import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { datadogRum } from '@datadog/browser-rum';
import {
  AuthAgentIDTokenResponsePayload,
  AuthErrorType,
} from '@store/auth/types';
import { ADB_COOKIES } from '@utils/cookie/adb-cookies';
import { getCookie, removeCookie } from '@utils/cookie/cookie-configurator';
import { refreshTokens } from '@utils/refresh-oauth-token';
import { getLanguageCookie } from 'contexts/language-context';
import { GraphQLError, GraphQLFormattedError } from 'graphql';
import { jwtDecode as jwt } from 'jwt-decode';
import { setGraphQLError } from './reactive-error';

const uri = import.meta.env.GRAPHQL_ENDPOINT;

const httpLink = createHttpLink({
  uri: ({ operationName }) => `${uri}?op=${operationName}`,
});

const refreshTokenErrorCodes = [
  AuthErrorType.ECOM_TOKEN_ERROR,
  AuthErrorType.USER_MISMATCH_ERROR,
  AuthErrorType.ACCESS_TOKEN_ERROR,
];

const shouldRefreshTokens = (error: GraphQLFormattedError) => {
  const errorCode = error.extensions?.smartErrorCode as AuthErrorType;

  if (refreshTokenErrorCodes.includes(errorCode)) return true;
  if (error.message.includes('Access token invalid or expired')) return true;

  return false;
};

const mapErrorMessage = (error: GraphQLFormattedError) => {
  const errorMessage = error.message;

  if (errorMessage.includes('Cannot return null for non-nullable field')) {
    const match = errorMessage.match(/field (.+)/);

    if (match) {
      const nullValue = match[1].slice(0, -1);
      if (error.extensions) {
        error.extensions.smartErrorCode = 'null_value';
        error.extensions.parameter = nullValue;
      }
    }
  }
  return error;
};

const ATTEMPT_KEY = 'token-refresh-attempt';

const refreshAttempt = () => {
  const attempt = Boolean(localStorage.getItem(ATTEMPT_KEY) ?? false);
  if (attempt) {
    localStorage.removeItem(ATTEMPT_KEY);
  }
  return attempt;
};

const setAttempt = () => {
  localStorage.setItem(ATTEMPT_KEY, 'true');
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const graphQLError = graphQLErrors?.[0];

  if (graphQLError) {
    graphQLErrors.forEach((error: any) => {
      datadogRum.addError(error);
    });

    const attempt = refreshAttempt();

    if (shouldRefreshTokens(graphQLError) && !attempt) {
      refreshTokens()
        .catch(() => removeCookie(ADB_COOKIES.TOKENS))
        .finally(() => setAttempt())
        .finally(() => window.location.reload());

      return;
    }

    setGraphQLError(mapErrorMessage(graphQLError));
  } else if (networkError) {
    if (
      networkError.message.includes('signal is aborted') ||
      networkError.message.includes('aborted a request')
    ) {
      return;
    }
    setGraphQLError(networkError as GraphQLError);
  }
});

const authLink = setContext((operation, { headers }) => {
  if (operation.operationName === 'Tokens') {
    return { headers };
  }
  // get the authentication token from cookie if it exists
  const AUTH_INFO = JSON.parse(getCookie(ADB_COOKIES.TOKENS) ?? '');
  const market = getCookie(ADB_COOKIES.MARKET);
  const { language } = getLanguageCookie();

  const decodedToken: AuthAgentIDTokenResponsePayload = jwt(
    AUTH_INFO['id-token']
  );

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Agent_id: decodedToken.sub,
      Access_token: AUTH_INFO['access-token'],
      Id_token: AUTH_INFO['id-token'],
      Market_id: market,
      lang: language,
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(errorLink).concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Appointment: {
        keyFields: ['id', 'type'],
      },
      ActivityAppointment: {
        keyFields: ['id', 'subjectAppointment'],
      },
      Task: {
        keyFields: ['id'],
      },
      Query: {
        fields: {
          allTasks: {
            merge(existing = {}, incoming = {}) {
              return { ...existing, ...incoming };
            },
          },
        },
      },
    },
  }),
});
