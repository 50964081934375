import { useTranslation } from 'react-i18next';
import { string } from 'yup';
import { GetMaxLengthValidationMsg, MarketCode } from '../helpers/utils';

export const EmailSchema = (validationMarket: MarketCode) => {
  const { t } = useTranslation();

  const defaultSchema = {
    email: string()
      .email(
        `${t('general.error_messages.validations.invalid')} ${t(
          'form_fields.basic.email'
        )}`
      )
      .required(
        `${t('form_fields.basic.email')} ${t(
          'general.error_messages.validations.is_required'
        )}`
      )
      .max(70, GetMaxLengthValidationMsg(t('form_fields.basic.email'), 70)),
  };

  if (validationMarket) {
    switch (validationMarket) {
      case 'de':
        return {
          email: string()
            .email(
              `${t('form_fields.basic.email')} ${t(
                'general.error_messages.validations.invalid'
              )}`
            )
            .required(
              `${t('form_fields.basic.email')} ${t(
                'general.error_messages.validations.is_required'
              )}`
            )
            .max(
              70,
              GetMaxLengthValidationMsg(t('form_fields.basic.email'), 70)
            ),
        };
      default:
        return defaultSchema;
    }
  }

  return defaultSchema;
};
