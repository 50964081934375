import Cookies from 'js-cookie';

import type { CookieConfig } from './types';

/**
 * get standard cookie configuration for secure cookies
 *
 * @returns CookieConfig
 */
const getCookieConfig = (): CookieConfig => ({
  sameSite: 'strict',
  secure: true,
  ...((!import.meta.env.CURRENT_ENV ||
    import.meta.env.CURRENT_ENV !== 'local') && {
    domain: import.meta.env.SITE_DOMAIN,
  }),
});

/**
 * get cookie
 *
 * @param key
 * @returns string | undefined
 */
export const getCookie = (key: string): string | undefined => Cookies.get(key);

/**
 * set cookie
 *
 * @param key string
 * @param value any
 * @returns string | undefined
 */
export const setCookie = (key: string, value: any): string | undefined =>
  Cookies.set(key, value, getCookieConfig());

/**
 * remove cookie
 *
 * @param key
 * @returns void
 */
export const removeCookie = (key: string): void =>
  Cookies.remove(key, getCookieConfig());
