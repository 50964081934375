import {
  AppointmentStatus,
  ExtendedCar,
  Maybe,
  OrderDetails,
  OrderStatus,
  Task,
  TaskCustomer,
  TaskStatus,
  TaskStep,
  TaskType,
} from '@smart/adb-shared';
import {
  AdbIconProps,
  IconBackground,
  IconColor,
} from '@smart/components-adb/atoms/AdbIcon/AdbIcon.config';
import { translateLabel } from '@smart/components-adb/molecules/OrderItems/translateLabel';
import { OrderPaymentStatusFilterValues } from '@ui/data-models/facet/facet.model';
import { formatDateAndTime } from '@ui/library/helpers/date';
import {
  AdbLocale,
  getCustomFormattedDate,
  isValidDate,
} from '@ui/library/helpers/date-locale';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';
import { translateTextIfExists } from './Table/tasktable.config';

export type TabId = 'all' | 'new' | 'open' | 'completed';

export const getCustomerPath = (
  customer: TaskCustomer,
  page: 'overview' | 'orders' | 'details'
) =>
  [
    `/customer/${page}/${customer.uuid}/${customer.sfCustomerId}`,
    customer.__typename === 'TaskBusinessCustomer'
      ? `/${customer.bpid}/${customer.sfOrgId}`
      : '',
  ].join('');

const getHandoverTaskDetails = (
  task: Task,
  t: (key: string | string[]) => string,
  locale: AdbLocale,
  customer: TaskCustomer,
  car?: ExtendedCar,
  order?: OrderDetails
) => ({
  'customer.search.headers.name': () => (
    <Link
      state={{ key: '/customer' }}
      to={getCustomerPath(customer, 'overview')}
    >{`${customer.firstName} ${customer.lastName}`}</Link>
  ),
  'form_fields.basic.mobile': customer.phoneNumber ?? '-',
  'task.preferred_date':
    isValidDate(task.requestedDate) && task.requestedDate
      ? getCustomFormattedDate(new Date(task.requestedDate), 'P', locale)
      : '-',
  'car.assets.handover_date':
    isValidDate(task.scheduledDate) && task.scheduledDate
      ? getCustomFormattedDate(new Date(task.scheduledDate), 'P', locale)
      : '-',
  'car.assets.order_number': task.orderNumber
    ? () => (
        <Link to={getCustomerPath(customer, 'orders')}>{task.orderNumber}</Link>
      )
    : '-',
  ...(car &&
    order && {
      'car.assets.vin': task.vin ?? '-',
      'car.features.model': car.name ?? '-',
      'car.features.exterior': car.exterior ?? '-',
      'car.features.interior': car.interior ?? '-',
      'car.assets.license_plate': car.licensePlateNumber ?? '-',
      'orders.payment_details.payment_status': order.paymentStatus
        ? translateLabel(OrderPaymentStatusFilterValues, order.paymentStatus, t)
        : '-',
      'orders.handover_details.registration_date': car.registrationDate ?? '-',
      'orders.transportation_status':
        order.status === OrderStatus.HandedOver
          ? t('orders.filter.handed_over')
          : t([
              `orders.transport_status.${order.extaStatus}`,
              'orders.not_available',
            ]),
    }),
});

const testDriveTaskDetails = (
  task: Task,
  t: TFunction<'translation', undefined>,
  locale: AdbLocale,
  customer: TaskCustomer
) => ({
  'customer.search.headers.name': () => (
    <Link
      state={{ key: '/customer' }}
      to={getCustomerPath(customer, 'overview')}
    >{`${customer.firstName} ${customer.lastName}`}</Link>
  ),
  'customer.search.headers.account': customer.type ?? '-',
  'form_fields.basic.mobile': customer.phoneNumber ?? '-',
  'form_fields.basic.email': customer.userId ?? '-',
  'form_fields.basic.postal_code': customer.postalCode ?? '-',
  'task.lead.call_attempts': task.callAttempts ?? '-',
  'car.features.model': task.vehicle?.productName ?? '-',
  'car.features.exterior': task.vehicle?.exterior ?? '-',
  'car.assets.license_plate': task.vehicle?.licensePlate ?? '-',
  'task.lead.lead_source': task.leadSource ?? '-',
  'task.lead.last_contacted': task.lastContacted
    ? formatDateAndTime(new Date(task.lastContacted), locale)
    : '-',
  'task.status.label': translateTextIfExists(
    t,
    `task.status.${task.appointment?.status?.toLowerCase()}`,
    task.appointment?.status ?? '-'
  ),
  'task.test_drive.timeslot_selected': task.scheduledDate
    ? formatDateAndTime(new Date(task.scheduledDate), locale)
    : '-',
});

const posIdentTaskDetails = (
  task: Task,
  t: (key: string) => string,
  locale: AdbLocale,
  customer: TaskCustomer
) => ({
  'customer.search.headers.name': () => (
    <Link
      state={{ key: '/customer' }}
      to={getCustomerPath(customer, 'overview')}
    >{`${customer.firstName} ${customer.lastName}`}</Link>
  ),
  'customer.search.headers.account': customer.type ?? '-',
  'form_fields.basic.mobile': customer.phoneNumber ?? '-',
  'form_fields.basic.email': customer.userId ?? '-',
  'task.pos_ident.request_created_at': task.requestCreatedDate
    ? formatDateAndTime(new Date(task.requestCreatedDate), locale)
    : '-',
  'task.pos_ident.request_completed_at': task.requestCompletedDate
    ? formatDateAndTime(new Date(task.requestCompletedDate), locale)
    : '-',
});

const leadTaskDetails = (
  task: Task,
  t: TFunction<'translation', undefined>,
  locale: AdbLocale,
  customer: TaskCustomer
) => ({
  'customer.search.headers.name': () => (
    <Link
      state={{ key: '/customer' }}
      to={getCustomerPath(customer, 'overview')}
    >{`${customer.firstName} ${customer.lastName}`}</Link>
  ),
  'customer.search.headers.account': translateTextIfExists(
    t,
    `task.account.${customer.type?.toLowerCase()}`,
    customer.type ?? t('task.account.no_account')
  ),
  'form_fields.basic.mobile': customer.phoneNumber ?? '-',
  'form_fields.basic.email': customer.userId ?? '-',
  'form_fields.basic.postal_code': customer.postalCode ?? '-',
  'task.lead.product_interest': task.productInterest ?? '-',
  'task.lead.lead_source': task.leadSource ?? '-',
  'task.lead.call_attempts': task.callAttempts ?? '-',
  'task.lead.last_contacted': task.lastContacted
    ? formatDateAndTime(new Date(task.lastContacted), locale)
    : '-',
});

const taskDetailsByType: Record<
  TaskType,
  (
    task: Task,
    t: TFunction<'translation', undefined>,
    locale: AdbLocale,
    customer: TaskCustomer,
    car?: ExtendedCar,
    order?: OrderDetails
  ) => Record<string, string | number | (() => JSX.Element)>
> = {
  Handover: getHandoverTaskDetails,
  Appointment: () => ({
    'translation.key': 'TBD',
  }),
  Service: () => ({
    'translation.key': 'TBD',
  }),
  Test_Drive: testDriveTaskDetails,
  Task: () => ({
    'customer.search.headers.last_contact': '-',
    'form_fields.system.source': '-',
  }),
  Lead: leadTaskDetails,
  POSIdent_Check: posIdentTaskDetails,
};

export const getTaskDetails = (
  task: Task,
  t: TFunction<'translation', undefined>,
  locale: AdbLocale,
  customer: TaskCustomer,
  car?: ExtendedCar,
  order?: OrderDetails
) =>
  taskDetailsByType[task.type ?? 'Task'](task, t, locale, customer, car, order);

export const getIconFromStatus = (
  status: Maybe<string> | undefined
): AdbIconProps => {
  const color = IconColor.WHITE;
  const backgroundColor = IconBackground.BLACK;
  switch (status) {
    case AppointmentStatus.Completed:
      return {
        icon: 'successful-checkmark',
        color,
        backgroundColor,
      };
    case AppointmentStatus.Scheduled:
      return {
        icon: 'minus',
        color,
        backgroundColor,
      };
    case AppointmentStatus.Requested:
      return {
        icon: 'error',
        color,
        backgroundColor,
      };
    default:
      return {
        icon: 'minus',
        color,
        backgroundColor,
      };
  }
};

export const getTaskAssignee = ({ task, t }: { task: Task; t: TFunction }) =>
  task.agent && task.isAgentAssigned
    ? task.agent.fullName
    : t('task.assign_an_expert');

export const isEvent = (task: Task) => task.step === TaskStep.Event;
export const isCompleted = (task: Task) => task.status === TaskStatus.Completed;
export const isAppointmentCompleted = (task: Task) =>
  task.appointment?.status === AppointmentStatus.Completed;
