import { AppointmentTimeSchema } from '@ui/validations/schemas/AppointmentTime';
import { DrivingLicenseNoSchema } from '@ui/validations/schemas/DrivingLicenseNo';
import { ReturnMileageSchema } from '@ui/validations/schemas/ReturnMileage';
import { MarketCode } from '@utils/market/types';
import { object } from 'yup';

const TestDriveContractReturnSchema = (market: MarketCode) => {
  const validationMarket = market.toUpperCase();

  const drivingLicenseNo = DrivingLicenseNoSchema(validationMarket, true);
  const returnMileage = ReturnMileageSchema(true);
  const appointmentTime = AppointmentTimeSchema(validationMarket, true);

  const schemaObject = object().shape({
    ...drivingLicenseNo,
    ...returnMileage,
    ...appointmentTime,
  });

  return schemaObject;
};

export default TestDriveContractReturnSchema;
