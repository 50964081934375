import { MarketCode } from '@utils/market/types';
import { matchPath } from 'react-router-dom';

declare global {
  interface Window {
    adobeDataLayer: any;
  }
}

export type PageType =
  | 'general'
  | 'home'
  | 'customer_area'
  | 'testdrive'
  | 'orders'
  | 'stock'
  | 'outlet';

export type PageName =
  | 'home'
  | 'calendar'
  | 'tasks'
  | 'customer'
  | 'customer.register'
  | 'customer.overview'
  | 'customer.detail'
  | 'customer.documents'
  | 'customer.orders'
  | 'customer.purchase_history'
  | 'testdrive.vehicle'
  | 'testdrive.timeslot'
  | 'testdrive.account'
  | 'testdrive.overview'
  | 'testdrive.confirmation'
  | 'orders'
  | 'stock'
  | 'outlet.demo_cars'
  | 'outlet.details';

export const pathnameToPageNameMap: Record<string, PageName> = {
  '/': 'home',
  '/calendar': 'calendar',
  '/tasks': 'tasks',
  '/customer': 'customer',
  '/customer/register': 'customer.register',
  '/customer/overview/:customerId/:sfCustomerId': 'customer.overview',
  '/customer/overview/:customerId/:sfCustomerId/:bpid/:sfOrgId':
    'customer.overview',
  '/customer/details/:customerId/:sfCustomerId': 'customer.detail',
  '/customer/details/:customerId/:sfCustomerId/:bpid/:sfOrgId':
    'customer.detail',
  '/customer/documents/:customerId/:sfCustomerId': 'customer.documents',
  '/customer/documents/:customerId/:sfCustomerId/:bpid/:sfOrgId':
    'customer.documents',
  '/customer/orders/:customerId/:sfCustomerId': 'customer.orders',
  '/customer/orders/:customerId/:sfCustomerId/:bpid/:sfOrgId':
    'customer.orders',
  '/customer/purchased-products/:customerId/:sfCustomerId':
    'customer.purchase_history',
  '/customer/purchased-products/:customerId/:sfCustomerId/:bpid/:sfOrgId':
    'customer.purchase_history',
  '/test-drive/car-selection': 'testdrive.vehicle',
  '/test-drive/time/car-time-selection': 'testdrive.timeslot',
  '/test-drive/confirmation/user-details': 'testdrive.account',
  '/test-drive/confirmation/summary': 'testdrive.overview',
  '/test-drive/confirmation/success': 'testdrive.confirmation',
  '/orders': 'orders',
  '/stock': 'stock',
  '/outlet/outlet-cars': 'outlet.demo_cars',
  '/outlet/details-and-team': 'outlet.details',
};

export const pageNameToPageTypeMap: Record<PageName, PageType> = {
  home: 'home',
  calendar: 'general',
  tasks: 'general',
  customer: 'customer_area',
  'customer.register': 'customer_area',
  'customer.overview': 'customer_area',
  'customer.detail': 'customer_area',
  'customer.documents': 'customer_area',
  'customer.orders': 'customer_area',
  'customer.purchase_history': 'customer_area',
  'testdrive.vehicle': 'testdrive',
  'testdrive.timeslot': 'testdrive',
  'testdrive.account': 'testdrive',
  'testdrive.overview': 'testdrive',
  'testdrive.confirmation': 'testdrive',
  orders: 'orders',
  stock: 'stock',
  'outlet.demo_cars': 'outlet',
  'outlet.details': 'outlet',
};

export const getPageNameFromPathname = (
  pathname: string
): PageName | undefined => {
  const matchingKey = Object.keys(pathnameToPageNameMap).find((key) =>
    matchPath(key, pathname)
  );

  return matchingKey ? pathnameToPageNameMap[matchingKey] : undefined;
};

type OutletData = {
  id: string;
  name: string;
};

export type TrackingUserData = {
  readonly loginStatus: 'logged_in';
  readonly customerId: string;
  readonly type: 'agent';
  readonly channel: 'adb';
  readonly outlet: OutletData[];
};

export interface DataLayerPageViewEvent {
  readonly event: 'pageview';

  readonly page: {
    readonly name: PageName;
    readonly type: PageType;
    readonly referringPage: string | null;
    readonly url: string;
  };

  readonly user: TrackingUserData;

  readonly application: {
    readonly id: 'adb';
    readonly version: string;
    readonly env: string;
    readonly language: string;
    readonly market: MarketCode;
    readonly currency: string;
  };
}

export type DataLayerEvent = DataLayerPageViewEvent;

export const pushToDataLayer = (event: DataLayerEvent): void => {
  ((window as any).adobeDataLayer = (window as any).adobeDataLayer || []).push(
    event
  );
};
