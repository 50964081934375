import Documents from '@smart/components-adb/documents';
import DocumentsOld from '@smart/components-adb/documents-old';
import { useFeatureFlag } from '@utils/configs/featureFlag';
import { DocumentsContextProvider } from 'components/documents-old/documents-context';
import { useCustomerContext } from 'contexts/customer-context';
import CustomerLayout from 'layouts/customer-layout/CustomerLayout';

const CustomerDocuments = () => {
  const { customer, customerIsLoading } = useCustomerContext();
  const useNewDocumentsFeature = useFeatureFlag({
    key: 'use-new-document-system',
    defaultValue: false,
  });

  return (
    <DocumentsContextProvider
      customer={customer}
      customerLoading={customerIsLoading}
    >
      <CustomerLayout>
        {useNewDocumentsFeature ? <Documents /> : <DocumentsOld />}
      </CustomerLayout>
    </DocumentsContextProvider>
  );
};

export default CustomerDocuments;
