import { PreferredTimeSchema } from '@ui/validations/schemas/PreferredTime';
import { object } from 'yup';

const CommunicationSchema = (validationMarket: any) => {
  let schemaObject = {};
  const preferredTime = PreferredTimeSchema(validationMarket, false);

  schemaObject = object().shape({
    ...preferredTime,
  });

  return schemaObject;
};

export default CommunicationSchema;
