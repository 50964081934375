import {
  CustomerAccountType,
  CustomerRecordType,
  CustomerRegistrationInput,
  CustomerType,
} from '@smart/adb-shared';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import { Button } from '@smart/react-components';
import { getValidRandomPassword } from '@ui/library/helpers/password';
import { getCustomerRoute } from '@utils/helpers/route';
import {
  getNavigationItem,
  handleNavigationStepLinks,
  stepStatusHandler,
} from '@utils/helpers/test-drive';
import { useCustomerContext } from 'contexts/customer-context';
import { useMarketContext } from 'contexts/market-context';
import { useNotificationContext } from 'contexts/notification-context';
import { useTestDriveContext } from 'contexts/test-drive-context';
import { useCustomerRegistrationMutation } from 'graphql/queries/customer.generated';
import { useCurrentOutletId } from 'hooks/outlet';
import FocusLayout from 'layouts/focus-layout/FocusLayout';
import AdbLayout from 'layouts/spacing-layout/AdbLayout';
import BasicInfo from 'pages/customer/details/BasicInfo/BasicInfo';
import {
  CustomerBusinessNewForm,
  CustomerPrivateNewForm,
} from 'pages/customer/details/helper';
import { TestDriveBookingSteps } from 'pages/test-drive/config';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  isCustomerEditFormDataValid,
  isCustomerFormDataValid,
  mapCustomerAddressValues,
} from '../helper';
import './UserDetails.scss';

const BASE_CLASS = 'adb-new-customer';

type LeadCustomerLocationState = {
  uuid: string;
};

/**
 * UserDetails component
 * @param UserDetails CustomerFormProps
 * @returns <UserDetails> ReactNode
 */

const UserDetails = () => {
  const { t } = useTranslation();
  const { customerId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    currentTestDriveBooking,
    setCurrentTestDriveBooking,
    navigation,
    setNavigation,
    setCustomerIds,
    setTestdriveEditSessionStorage,
  } = useTestDriveContext();
  const { customer, customerIsLoading } = useCustomerContext();
  const [params, setParams] = useSearchParams();
  // get the uuid of the customer type lead for transformation to full account.
  const leadCustomerLocationState = location.state as LeadCustomerLocationState;

  /**
   * ------------------------------------------------------------
   * Local states
   * ------------------------------------------------------------
   */

  // is new customer information valid
  const [isValidCustomer, setIsValidCustomer] = useState<boolean>(!!customerId);

  // notification text
  const { addSuccess, addError } = useNotificationContext();

  // if new customer is selected, true per default since user lands on this page when selecting customer
  const [selectingCustomer, setSelectingCustomer] = useState<boolean>(true);

  /**
   * ------------------------------------------------------------
   * Selectors
   * ------------------------------------------------------------
   */

  const selectedTestDriveNavigation = navigation;

  const { market } = useMarketContext();

  const outlet = useCurrentOutletId();

  /**
   * ------------------------------------------------------------
   *  Navigation functions
   * ------------------------------------------------------------
   */

  // dispatch the latest test drive navigation information
  const dispatchTestDriveNavigation = () => {
    const path = [
      `/test-drive/confirmation/summary/${customer?.uuid}/${customer?.sfCustomerId}`,
      customer?.__typename === 'CustomerBusiness'
        ? `/${customer.bpid}/${customer.sfOrgId}`
        : '',
    ].join('');
    navigate(path);
  };

  /**
   * ------------------------------------------------------------
   *  UseEffect for navigation
   * ------------------------------------------------------------
   */

  useEffect(() => {
    if (!selectingCustomer) {
      dispatchTestDriveNavigation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectingCustomer]);

  // function to update navigation header status
  const changeHeaderNavStatus = () => {
    // Dispatch test drive navigation steps status change
    const stepsStatus = {
      [TestDriveBookingSteps.ACCOUNT]: true,
      [TestDriveBookingSteps.CONFIRMATION]: true,
    };

    const updatedNavList = stepStatusHandler(
      selectedTestDriveNavigation,
      stepsStatus
    );

    setNavigation(updatedNavList);
  };

  useEffect(() => {
    if (
      customer &&
      customer.uuid !== currentTestDriveBooking.customerId &&
      customer.sfCustomerId !== currentTestDriveBooking.sfCustomerId
    ) {
      setSelectingCustomer(true);
      setCustomerIds(customer.uuid, customer.sfCustomerId ?? '');
    }
  }, [
    customer,
    customer?.uuid,
    customer?.sfCustomerId,
    currentTestDriveBooking.customerId,
    currentTestDriveBooking.sfCustomerId,
    setCustomerIds,
  ]);

  const isNextButtonDisabled = (!!customerId && !isValidCustomer) ?? false;

  const [
    registerCustomer,
    {
      data: registerCustomerMutationData,
      loading: registerCustomerMutationLoading,
    },
  ] = useCustomerRegistrationMutation();

  useEffect(() => {
    if (
      registerCustomerMutationLoading ||
      !registerCustomerMutationData?.customerRegistration
    )
      return;

    const { success: registerCustomerSuccess } =
      registerCustomerMutationData.customerRegistration;

    if (registerCustomerSuccess) {
      changeHeaderNavStatus();
      setSelectingCustomer(false);
      addSuccess({
        label: t('customer.register.success'),
        message: t('customer.register.save_customer_success'),
      });
    } else {
      addError({
        label: t('customer.register.error'),
        message: t('customer.register.save_customer_error'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    registerCustomerMutationData?.customerRegistration.success,
    registerCustomerMutationLoading,
  ]);

  // submit function to get the form details
  const handleCustomerDetailSubmit = async (updatedFormValues: any) => {
    // update customer in case of full account & not lead
    if (customerId) {
      setCurrentTestDriveBooking({
        ...currentTestDriveBooking,
        customerId,
      });
      setSelectingCustomer(false);
      changeHeaderNavStatus();
    } else {
      // new customer & transform to full account customer
      const newCustomerInput: CustomerRegistrationInput = {
        address: {
          street: updatedFormValues.street ?? '',
          regionCode: updatedFormValues.regionCode ?? '',
          town: updatedFormValues.town ?? '',
          postalCode: updatedFormValues.postalCode ?? '',
          countryCode: updatedFormValues.countryCode ?? '',
        },
        firstName: updatedFormValues.firstName ?? '',
        lastName: updatedFormValues.lastName ?? '',
        market: market.toUpperCase(),
        mobileNumber: updatedFormValues.mobileNumber ?? '',
        outletId: outlet?.mcsId ?? '',
        preferredLanguage: updatedFormValues?.preferredLanguage ?? '',
        recordType: updatedFormValues?.accountType ?? '',
        titleCode: updatedFormValues.titleCode ?? '',
        userId: updatedFormValues.userId ?? '',
        ...(updatedFormValues.accountType === CustomerAccountType.Private && {
          consents: {
            preferences: [
              {
                name: 'smart_termsOfService_EU',
                isConsentGranted: true,
              },
              {
                name: 'smart_privacyPolicy_EU',
                isConsentGranted: true,
              },
              {
                name: 'smart_ecosystem_EU',
                isConsentGranted: true,
              },
            ],
          },
        }),
        ...(updatedFormValues.accountType === CustomerAccountType.Private &&
          updatedFormValues?.dateOfBirth && {
            dateOfBirth: updatedFormValues.dateOfBirth ?? '',
          }),
        ...(updatedFormValues.accountType === CustomerAccountType.Private && {
          otp: getValidRandomPassword(),
        }),
        ...(updatedFormValues.accountType === CustomerAccountType.Business &&
          updatedFormValues?.vatRegistrationNumber && {
            companyName: updatedFormValues?.companyName,
          }),
        ...(updatedFormValues.accountType === CustomerAccountType.Business &&
          updatedFormValues?.vatRegistrationNumber && {
            vatRegistrationNumber: updatedFormValues?.vatRegistrationNumber,
          }),
      };

      const r = await registerCustomer({
        variables: {
          input: newCustomerInput,
        },
      });

      if (r.data?.customerRegistration.success) {
        // Return to customer listing
        navigate('/test-drive/confirmation/user-details', {
          replace: true,
        });
      }
    }
  };

  // Callback for the submit button from basic info
  let submitHandler: any;

  const bindSubmitForm = (formikSubmitFormHandler: any) => {
    submitHandler = formikSubmitFormHandler;
  };

  // Callback for the footer button when it is clicked
  const handleClickFooterNextButton = () => submitHandler && submitHandler();

  const checkDataValidity = (
    formValues: CustomerPrivateNewForm | CustomerBusinessNewForm
  ) => {
    if (!customerId) {
      setIsValidCustomer(!!isCustomerFormDataValid(formValues));
    } else {
      const mappedValues = mapCustomerAddressValues(formValues);
      setIsValidCustomer(!!isCustomerEditFormDataValid(mappedValues));
    }
  };

  const getFormTitle = () =>
    customerId
      ? `${customer?.firstName ?? ''} ${customer?.lastName ?? ''}`
      : `${t('customer.register.new_customer')}`;

  const navigateToCustomerDetails = async () => {
    await setParams({ from: 'test-drive' });

    setTestdriveEditSessionStorage(currentTestDriveBooking, navigation);

    navigate(`${getCustomerRoute(customer, 'details')}?${params.toString()}`);
  };

  const existingCustomer = customerId ?? leadCustomerLocationState?.uuid;

  return (
    <FocusLayout
      navList={handleNavigationStepLinks(
        selectedTestDriveNavigation,
        customer?.uuid,
        customer?.sfCustomerId ?? undefined
      )}
      nextButtonRoute={`/test-drive/confirmation/summary/${customer?.uuid}/${customer?.sfCustomerId}`}
      backButtonRoute={
        getNavigationItem(
          selectedTestDriveNavigation,
          TestDriveBookingSteps.ACCOUNT
        ).path
      }
      pathParam="customerId"
      footerRight={
        <div className="customer_footer_right_buttons">
          {existingCustomer && (
            <Button
              mode={200}
              variant="secondary"
              onClick={navigateToCustomerDetails}
            >
              {t('test_drive.summary.buttons.edit')}
            </Button>
          )}
          <Button
            loading={registerCustomerMutationLoading}
            disabled={isNextButtonDisabled}
            mode={200}
            variant="primary"
            onClick={handleClickFooterNextButton}
          >
            <Button.Spinner />
            {customerId && !leadCustomerLocationState?.uuid
              ? `${t('test_drive.account.buttons.choose_customer')}`
              : `${t('test_drive.account.buttons.create_customer')}`}
          </Button>
        </div>
      }
    >
      <AdbLayout.Content>
        <BasicInfo
          customerDetails={
            existingCustomer
              ? {
                  firstName: customer?.firstName ?? '',
                  lastName: customer?.lastName ?? '',
                  market: customer?.market ?? '',
                  mobileNumber: customer?.mobileNumber ?? '',
                  preferredLanguage: customer?.preferredLanguage ?? '',
                  titleCode: customer?.titleCode ?? '',
                  userId: customer?.userId ?? '',
                  uuid: customer?.uuid ?? '',
                  lastModified: customer?.lastModified ?? '',
                  recordType:
                    customer?.recordType ?? CustomerRecordType.PersonAccount,
                  sfCustomerId: customer?.sfCustomerId ?? '',
                  type: customer?.type ?? CustomerType.Account,
                  street: customer?.address?.street ?? '',
                  town: customer?.address?.town ?? '',
                  countryCode: customer?.address?.countryCode ?? '',
                  postalCode: customer?.address?.postalCode ?? '',
                  regionCode: customer?.address?.regionCode ?? '',
                  accountType:
                    customer?.accountType ?? CustomerAccountType.Private,
                  sfOrgId:
                    customer?.__typename === 'CustomerBusiness'
                      ? customer?.sfOrgId
                      : '',
                  bpid:
                    customer?.__typename === 'CustomerBusiness'
                      ? customer?.bpid
                      : '',
                  companyName:
                    customer?.__typename === 'CustomerBusiness'
                      ? customer?.companyName
                      : '',
                  vatRegistrationNumber:
                    customer?.__typename === 'CustomerBusiness'
                      ? customer?.vatRegistrationNumber
                      : '',
                  companyRegistrationNumber:
                    customer?.__typename === 'CustomerBusiness'
                      ? customer?.companyRegistrationNumber
                      : '',
                }
              : {
                  firstName: '',
                  lastName: '',
                  market: '',
                  mobileNumber: '',
                  preferredLanguage: '',
                  titleCode: '',
                  userId: '',
                  uuid: '',
                  lastModified: '',
                  recordType: CustomerRecordType.PersonAccount,
                  sfCustomerId: '',
                  type: CustomerType.Account,
                  street: '',
                  town: '',
                  countryCode: '',
                  postalCode: '',
                  regionCode: '',
                  accountType: CustomerAccountType.Private,
                  vatRegistrationNumber: '',
                }
          }
          handleCustomerDetailSubmit={handleCustomerDetailSubmit}
          bindSubmitForm={bindSubmitForm}
          checkDataValidity={checkDataValidity}
          title={getFormTitle()}
          isNotAccordion
          radioButtonTab={!customerId}
          disableAllFields={Boolean(existingCustomer)}
        />
      </AdbLayout.Content>

      {customerIsLoading && (
        <LoadingIndicator
          onFullPage
          additionalClasses={`${BASE_CLASS}__loading`}
        />
      )}
    </FocusLayout>
  );
};

export default UserDetails;
