import { useTranslation } from 'react-i18next';
import { number, object, string } from 'yup';

export const OrderLeasingDetailsSchema = () => {
  const { t } = useTranslation();

  const schemaObject = object().shape({
    leasingId: string().typeError(
      `${t('general.error_messages.validations.invalid')} ${t(
        'orders.payment_details.leasing_id'
      )} `
    ),
    leasingRate: number().typeError(
      `${t('general.error_messages.validations.invalid')} ${t(
        'orders.payment_details.leasing_rate'
      )} `
    ),
    mileage: number().typeError(
      `${t('general.error_messages.validations.invalid')} ${t(
        'orders.payment_details.mileage'
      )} `
    ),
    period: number().typeError(
      `${t('general.error_messages.validations.invalid')} ${t(
        'orders.payment_details.period'
      )} `
    ),
  });

  return schemaObject;
};
