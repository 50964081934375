import AdbLayout from 'layouts/spacing-layout/AdbLayout';
import './Documents.scss';

const BASE_CLASS = 'adb-customer-document';

const Documents = () => (
  <div className={BASE_CLASS}>
    <AdbLayout.Header backgroundColor="bg-level-2" />
    <AdbLayout.Content />
  </div>
);

export default Documents;
