import { AppointmentTimeSchema } from '@ui/validations/schemas/AppointmentTime';
import { DepartureMileageSchema } from '@ui/validations/schemas/DepartureMileage';
import { DrivingLicenseNoSchema } from '@ui/validations/schemas/DrivingLicenseNo';
import { MarketCode } from '@utils/market/types';
import { object } from 'yup';

const TestDriveContractSchema = (market: MarketCode) => {
  const validationMarket = market.toUpperCase();

  const drivingLicenseNo = DrivingLicenseNoSchema(validationMarket, true);
  const departureMileage = DepartureMileageSchema(true);
  const appointmentTime = AppointmentTimeSchema(validationMarket, true);

  const schemaObject = object().shape({
    ...drivingLicenseNo,
    ...departureMileage,
    ...appointmentTime,
  });

  return schemaObject;
};

export default TestDriveContractSchema;
