import { CommercialRegisterNrSchema } from '@ui/validations/schemas/CommercialRegisterNr';
import { LegalFormSchema } from '@ui/validations/schemas/LegalForm';
import { object } from 'yup';

const BusinessCompanyInfoSchema = (validationMarket: any) => {
  let schemaObject = {};
  const legalForm = LegalFormSchema(validationMarket, false);

  const commercialRegisterNo = CommercialRegisterNrSchema(
    validationMarket,
    false
  );

  schemaObject = object().shape({
    ...legalForm,
    ...commercialRegisterNo,
  });

  return schemaObject;
};

export default BusinessCompanyInfoSchema;
