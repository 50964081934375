import { CountryOfIdSchema } from '@ui/validations/schemas/CountryOfId';
import { DateOfBirthSchema } from '@ui/validations/schemas/DateOfBirth';
import { IdNumberSchema } from '@ui/validations/schemas/IdNumber';
import { PlaceOfBirthSchema } from '@ui/validations/schemas/PlaceOfBirth';
import { object } from 'yup';

const PrivateAdditionalInfoSchema = (validationMarket: string) => {
  const dateOfBirth = DateOfBirthSchema(validationMarket, false);
  const placeOfBirth = PlaceOfBirthSchema(validationMarket, false);
  const idNumber = IdNumberSchema(validationMarket, false);
  const countryOfId = CountryOfIdSchema(validationMarket, false);

  return object().shape({
    ...dateOfBirth,
    ...placeOfBirth,
    ...idNumber,
    ...countryOfId,
  });
};

export default PrivateAdditionalInfoSchema;
