import { useTranslation } from 'react-i18next';
import { string } from 'yup';

import { REGEX_FORMS } from '../configs/forms';
import { MarketCode } from '../helpers/utils';

export const PreferredLanguageSchema = (
  validationMarket: MarketCode,
  required = false
) => {
  const { t } = useTranslation();

  let schemaObject = {};

  if (validationMarket) {
    switch (validationMarket) {
      case 'de':
        schemaObject = {
          preferredLanguage: required
            ? string()
                .required(
                  `${t(
                    'form_fields.communication.preferred_language_communication'
                  )} ${t('general.error_messages.validations.is_required')}`
                )
                .matches(
                  REGEX_FORMS.stringCharacters.de,
                  `${t(
                    'form_fields.communication.preferred_language_communication'
                  )} ${t('general.error_messages.validations.invalid')}`
                )
            : string().matches(
                REGEX_FORMS.stringCharacters.de,
                `${t(
                  'form_fields.communication.preferred_language_communication'
                )} ${t('general.error_messages.validations.invalid')}`
              ),
        };
        break;
      default:
        schemaObject = {
          preferredLanguage: required
            ? string()
                .required(
                  `${t(
                    'form_fields.communication.preferred_language_communication'
                  )} ${t('general.error_messages.validations.is_required')}`
                )
                .matches(
                  REGEX_FORMS.stringCharacters.default,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.communication.preferred_language_communication'
                  )} `
                )
            : string().matches(
                REGEX_FORMS.stringCharacters.default,
                `${t('general.error_messages.validations.invalid')} ${t(
                  'form_fields.communication.preferred_language_communication'
                )} `
              ),
        };
        break;
    }
  }

  return schemaObject;
};
