import { useTranslation } from 'react-i18next';
import { string } from 'yup';

export const LegalFormSchema = (validationMarket: string, required = false) => {
  const { t } = useTranslation();

  const legalFormRegex = /^[a-zA-Z0-9\s]+$/g;

  let schemaObject = {};

  if (validationMarket) {
    switch (validationMarket) {
      case 'DE':
        schemaObject = {
          legalForm: required
            ? string()
                .required(
                  `${t('form_fields.company.legal_form')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`
                )
                .matches(
                  legalFormRegex,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.company.legal_form'
                  )} `
                )
            : string().matches(
                legalFormRegex,
                `${t('general.error_messages.validations.invalid')} ${t(
                  'form_fields.company.legal_form'
                )} `
              ),
        };
        break;
      default:
        schemaObject = {
          legalForm: required
            ? string()
                .required(
                  `${t('form_fields.company.legal_form')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`
                )
                .matches(
                  legalFormRegex,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.company.legal_form'
                  )} `
                )
            : string().matches(
                legalFormRegex,
                `${t('general.error_messages.validations.invalid')} ${t(
                  'form_fields.company.legal_form'
                )} `
              ),
        };
        break;
    }
  }

  return schemaObject;
};
