import type { CustomerConsentPreference } from '@smart/adb-shared';
import Section from '@smart/components-adb/atoms/Section/Section';
import { Checkbox } from '@smart/react-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerForm } from '../helper';

export const Preferences: CustomerConsentPreference[] = [
  {
    name: 'smart_consentPhone_EU',
    isConsentGranted: false,
  },
  {
    name: 'smart_consentEmail_EU',
    isConsentGranted: false,
  },
  {
    name: 'smart_consentDirectMail_EU',
    isConsentGranted: false,
  },
  {
    name: 'smart_termsOfService_EU',
    isConsentGranted: false,
  },
  {
    name: 'smart_privacyPolicy_EU',
    isConsentGranted: false,
  },
];

const BASE_CLASS = 'adb-details';

/**
 * @type ConsentsProps
 * Props types for Consents component
 */
export type ConsentsProps = CustomerForm & {
  consentDetails: (CustomerConsentPreference | null)[];
};

/**
 * Consents component
 * @param ConsentsProps ConsentsProps
 * @returns <Consents> ReactNode
 */
const Consents = ({ consentDetails, hasExpanded }: ConsentsProps) => {
  const { t } = useTranslation();

  const [currentConsents, setCurrentConsents] = useState(Preferences);

  useEffect(() => {
    if (consentDetails?.length) {
      const preferencesWithConsent = Preferences.map((preference) => {
        const apiPreference = consentDetails.find(
          (apiPreferences) =>
            apiPreferences && apiPreferences.name === preference.name
        );

        return apiPreference ?? preference;
      });
      setCurrentConsents(preferencesWithConsent);
    }
  }, [consentDetails]);

  return (
    <Section
      expanded={!!hasExpanded}
      title={t('customer.details.subscription_consent')}
      hasAccordion
    >
      <div
        className={`${BASE_CLASS}__form-wrap ${BASE_CLASS}__form-wrap--checkbox-row`}
      >
        {currentConsents &&
          currentConsents?.length > 0 &&
          currentConsents?.map((consent) => (
            <div className={`${BASE_CLASS}__form-checkbox`} key={consent.name}>
              <Checkbox
                id={consent.name}
                name={consent.name}
                checked={consent.isConsentGranted}
                value=""
                disabled
              >
                {t(`form_fields.consent.preferences.${consent.name}`)}
              </Checkbox>
            </div>
          ))}
      </div>
    </Section>
  );
};

export default Consents;
