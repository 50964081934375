import { useTranslation } from 'react-i18next';
import { string } from 'yup';

export const CommercialRegisterNrSchema = (
  validationMarket: string,
  required = false
) => {
  const { t } = useTranslation();

  const commercialRegisterNoRegex = /^[a-zA-Z0-9\s]+$/g;

  let schemaObject = {};

  if (validationMarket) {
    switch (validationMarket) {
      case 'DE':
        schemaObject = {
          commercialRegisterNo: required
            ? string()
                .required(
                  `${t('form_fields.company.commercial_registerNr')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`
                )
                .matches(
                  commercialRegisterNoRegex,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.company.commercial_registerNr'
                  )} `
                )
                .max(100)
            : string()
                .matches(
                  commercialRegisterNoRegex,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.company.commercial_registerNr'
                  )} `
                )
                .max(100),
        };
        break;
      default:
        schemaObject = {
          commercialRegisterNo: required
            ? string()
                .required(
                  `${t('form_fields.company.commercial_registerNr')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`
                )
                .matches(
                  commercialRegisterNoRegex,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.company.commercial_registerNr'
                  )} `
                )
                .max(100)
            : string()
                .matches(
                  commercialRegisterNoRegex,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.company.commercial_registerNr'
                  )} `
                )
                .max(100),
        };
        break;
    }
  }

  return schemaObject;
};
