import { Document } from '@smart/adb-shared';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { Text } from '@smart/react-components';
import { useTranslation } from 'react-i18next';
import { useLoadSignUrlQuery } from '../queries.generated';

import './DocumentSignDialog.scss';

const BASE_CLASS = 'adb-document-sign-dialog';

export const DocumentSignDialog = ({
  document,
  onClose,
}: {
  document: Document;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { data, loading } = useLoadSignUrlQuery({
    variables: {
      input: {
        id: document.id,
      },
    },
  });
  const signURL = data?.loadSignURL.signURL;

  return (
    <AdbDialog id="sign-document-dialog" onClose={onClose} size="maximized">
      <AdbDialog.Header>
        <Text variant="hl-100">{t('task.handover.sign_documents')}</Text>
      </AdbDialog.Header>
      <AdbDialog.Content>
        {!loading ? (
          <object data={signURL} className={`${BASE_CLASS}__signature-object`}>
            <embed src={signURL} />
          </object>
        ) : (
          <LoadingIndicator />
        )}
      </AdbDialog.Content>
    </AdbDialog>
  );
};
