import { useTranslation } from 'react-i18next';
import { string } from 'yup';

import { REGEX_FORMS } from '../configs/forms';
import { MarketCode } from '../helpers/utils';

export const TitleSchema = (validationMarket: MarketCode, required = false) => {
  const { t } = useTranslation();

  let schemaObject = {};

  if (validationMarket) {
    switch (validationMarket) {
      case 'de':
        schemaObject = {
          titleCode: required
            ? string().required(
                `${t('form_fields.basic.title')} ${t(
                  'general.error_messages.validations.is_required'
                )}`
              )
            : string().matches(
                REGEX_FORMS.stringCharacters.de,
                `${t('form_fields.basic.title')} ${t(
                  'general.error_messages.validations.invalid'
                )}`
              ),
        };
        break;
      default:
        schemaObject = {
          titleCode: required
            ? string().required(
                `${t('form_fields.basic.title')} ${t(
                  'general.error_messages.validations.is_required'
                )}`
              )
            : string().matches(
                REGEX_FORMS.stringCharacters.default,
                `${t('general.error_messages.validations.invalid')} ${t(
                  'form_fields.basic.title'
                )} `
              ),
        };
        break;
    }
  }

  return schemaObject;
};
