/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DocumentBulkDownloadMutationVariables = Types.Exact<{
  input: Types.DocumentBulkDownloadInput;
}>;


export type DocumentBulkDownloadMutation = { __typename?: 'Mutation', documentBulkDownload: { __typename?: 'DocumentBulkDownloadResult', signedUrl: string } };

export type PurchasedProductsQueryVariables = Types.Exact<{
  input: Types.CarsInput;
}>;


export type PurchasedProductsQuery = { __typename?: 'Query', getCars: { __typename?: 'CarResponse', products: Array<{ __typename?: 'ExtendedCar', id: string, type: Types.AssetType, vin: string, image: string, orderNumber?: string | null }> } };

export type LoadDocumentsQueryVariables = Types.Exact<{
  input: Types.DocumentSearchInput;
}>;


export type LoadDocumentsQuery = { __typename?: 'Query', documentSearch: { __typename?: 'DocumentSearchResult', documents: Array<{ __typename?: 'Document', dateModified: string, id: string, name: string, transactionId: string, customerId?: string | null, bpId?: string | null, signURL?: string | null, category?: string | null, status?: string | null, type?: string | null }> } };

export type LoadSignUrlQueryVariables = Types.Exact<{
  input: Types.LoadSignUrlInput;
}>;


export type LoadSignUrlQuery = { __typename?: 'Query', loadSignURL: { __typename?: 'LoadSignURLResult', signURL: string } };

export type DocumentRenameMutationVariables = Types.Exact<{
  input: Types.DocumentRenameInput;
}>;


export type DocumentRenameMutation = { __typename?: 'Mutation', documentRename: { __typename?: 'DocumentRenameResult', status: number } };

export type DocumentDeleteMutationVariables = Types.Exact<{
  input: Types.DocumentDeleteInput;
}>;


export type DocumentDeleteMutation = { __typename?: 'Mutation', documentDelete: { __typename?: 'DocumentDeleteResult', status: number } };


export const DocumentBulkDownloadDocument = gql`
    mutation DocumentBulkDownload($input: DocumentBulkDownloadInput!) {
  documentBulkDownload(input: $input) {
    signedUrl
  }
}
    `;
export type DocumentBulkDownloadMutationFn = Apollo.MutationFunction<DocumentBulkDownloadMutation, DocumentBulkDownloadMutationVariables>;

/**
 * __useDocumentBulkDownloadMutation__
 *
 * To run a mutation, you first call `useDocumentBulkDownloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentBulkDownloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentBulkDownloadMutation, { data, loading, error }] = useDocumentBulkDownloadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentBulkDownloadMutation(baseOptions?: Apollo.MutationHookOptions<DocumentBulkDownloadMutation, DocumentBulkDownloadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DocumentBulkDownloadMutation, DocumentBulkDownloadMutationVariables>(DocumentBulkDownloadDocument, options);
      }
export type DocumentBulkDownloadMutationHookResult = ReturnType<typeof useDocumentBulkDownloadMutation>;
export type DocumentBulkDownloadMutationResult = Apollo.MutationResult<DocumentBulkDownloadMutation>;
export type DocumentBulkDownloadMutationOptions = Apollo.BaseMutationOptions<DocumentBulkDownloadMutation, DocumentBulkDownloadMutationVariables>;
export const PurchasedProductsDocument = gql`
    query PurchasedProducts($input: CarsInput!) {
  getCars(input: $input) {
    products {
      id
      type
      vin
      image
      orderNumber
    }
  }
}
    `;

/**
 * __usePurchasedProductsQuery__
 *
 * To run a query within a React component, call `usePurchasedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchasedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchasedProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchasedProductsQuery(baseOptions: Apollo.QueryHookOptions<PurchasedProductsQuery, PurchasedProductsQueryVariables> & ({ variables: PurchasedProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchasedProductsQuery, PurchasedProductsQueryVariables>(PurchasedProductsDocument, options);
      }
export function usePurchasedProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchasedProductsQuery, PurchasedProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchasedProductsQuery, PurchasedProductsQueryVariables>(PurchasedProductsDocument, options);
        }
export function usePurchasedProductsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PurchasedProductsQuery, PurchasedProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PurchasedProductsQuery, PurchasedProductsQueryVariables>(PurchasedProductsDocument, options);
        }
export type PurchasedProductsQueryHookResult = ReturnType<typeof usePurchasedProductsQuery>;
export type PurchasedProductsLazyQueryHookResult = ReturnType<typeof usePurchasedProductsLazyQuery>;
export type PurchasedProductsSuspenseQueryHookResult = ReturnType<typeof usePurchasedProductsSuspenseQuery>;
export type PurchasedProductsQueryResult = Apollo.QueryResult<PurchasedProductsQuery, PurchasedProductsQueryVariables>;
export const LoadDocumentsDocument = gql`
    query LoadDocuments($input: DocumentSearchInput!) {
  documentSearch(input: $input) {
    documents {
      dateModified
      id
      name
      transactionId
      customerId
      bpId
      signURL
      category
      status
      type
    }
  }
}
    `;

/**
 * __useLoadDocumentsQuery__
 *
 * To run a query within a React component, call `useLoadDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadDocumentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadDocumentsQuery(baseOptions: Apollo.QueryHookOptions<LoadDocumentsQuery, LoadDocumentsQueryVariables> & ({ variables: LoadDocumentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadDocumentsQuery, LoadDocumentsQueryVariables>(LoadDocumentsDocument, options);
      }
export function useLoadDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadDocumentsQuery, LoadDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadDocumentsQuery, LoadDocumentsQueryVariables>(LoadDocumentsDocument, options);
        }
export function useLoadDocumentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LoadDocumentsQuery, LoadDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LoadDocumentsQuery, LoadDocumentsQueryVariables>(LoadDocumentsDocument, options);
        }
export type LoadDocumentsQueryHookResult = ReturnType<typeof useLoadDocumentsQuery>;
export type LoadDocumentsLazyQueryHookResult = ReturnType<typeof useLoadDocumentsLazyQuery>;
export type LoadDocumentsSuspenseQueryHookResult = ReturnType<typeof useLoadDocumentsSuspenseQuery>;
export type LoadDocumentsQueryResult = Apollo.QueryResult<LoadDocumentsQuery, LoadDocumentsQueryVariables>;
export const LoadSignUrlDocument = gql`
    query LoadSignURL($input: LoadSignURLInput!) {
  loadSignURL(input: $input) {
    signURL
  }
}
    `;

/**
 * __useLoadSignUrlQuery__
 *
 * To run a query within a React component, call `useLoadSignUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadSignUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadSignUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoadSignUrlQuery(baseOptions: Apollo.QueryHookOptions<LoadSignUrlQuery, LoadSignUrlQueryVariables> & ({ variables: LoadSignUrlQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadSignUrlQuery, LoadSignUrlQueryVariables>(LoadSignUrlDocument, options);
      }
export function useLoadSignUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadSignUrlQuery, LoadSignUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadSignUrlQuery, LoadSignUrlQueryVariables>(LoadSignUrlDocument, options);
        }
export function useLoadSignUrlSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LoadSignUrlQuery, LoadSignUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LoadSignUrlQuery, LoadSignUrlQueryVariables>(LoadSignUrlDocument, options);
        }
export type LoadSignUrlQueryHookResult = ReturnType<typeof useLoadSignUrlQuery>;
export type LoadSignUrlLazyQueryHookResult = ReturnType<typeof useLoadSignUrlLazyQuery>;
export type LoadSignUrlSuspenseQueryHookResult = ReturnType<typeof useLoadSignUrlSuspenseQuery>;
export type LoadSignUrlQueryResult = Apollo.QueryResult<LoadSignUrlQuery, LoadSignUrlQueryVariables>;
export const DocumentRenameDocument = gql`
    mutation DocumentRename($input: DocumentRenameInput!) {
  documentRename(input: $input) {
    status
  }
}
    `;
export type DocumentRenameMutationFn = Apollo.MutationFunction<DocumentRenameMutation, DocumentRenameMutationVariables>;

/**
 * __useDocumentRenameMutation__
 *
 * To run a mutation, you first call `useDocumentRenameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentRenameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentRenameMutation, { data, loading, error }] = useDocumentRenameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentRenameMutation(baseOptions?: Apollo.MutationHookOptions<DocumentRenameMutation, DocumentRenameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DocumentRenameMutation, DocumentRenameMutationVariables>(DocumentRenameDocument, options);
      }
export type DocumentRenameMutationHookResult = ReturnType<typeof useDocumentRenameMutation>;
export type DocumentRenameMutationResult = Apollo.MutationResult<DocumentRenameMutation>;
export type DocumentRenameMutationOptions = Apollo.BaseMutationOptions<DocumentRenameMutation, DocumentRenameMutationVariables>;
export const DocumentDeleteDocument = gql`
    mutation DocumentDelete($input: DocumentDeleteInput!) {
  documentDelete(input: $input) {
    status
  }
}
    `;
export type DocumentDeleteMutationFn = Apollo.MutationFunction<DocumentDeleteMutation, DocumentDeleteMutationVariables>;

/**
 * __useDocumentDeleteMutation__
 *
 * To run a mutation, you first call `useDocumentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentDeleteMutation, { data, loading, error }] = useDocumentDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentDeleteMutation(baseOptions?: Apollo.MutationHookOptions<DocumentDeleteMutation, DocumentDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DocumentDeleteMutation, DocumentDeleteMutationVariables>(DocumentDeleteDocument, options);
      }
export type DocumentDeleteMutationHookResult = ReturnType<typeof useDocumentDeleteMutation>;
export type DocumentDeleteMutationResult = Apollo.MutationResult<DocumentDeleteMutation>;
export type DocumentDeleteMutationOptions = Apollo.BaseMutationOptions<DocumentDeleteMutation, DocumentDeleteMutationVariables>;