import { Customer } from '@smart/adb-shared';
import DocumentsOld from '@smart/components-adb/documents-old';
import { UploadType } from '@smart/components-adb/documents-old/Documents.config';
import { useFeatureFlag } from '@utils/configs/featureFlag';
import { DocumentsContextProvider } from 'components/documents-old/documents-context';
import { useCurrentOutlet } from 'hooks/outlet';

const OutletDocuments = () => {
  const outlet = useCurrentOutlet();
  const useNewDocumentsFeature = useFeatureFlag({
    key: 'use-new-document-system',
    defaultValue: false,
  });

  return (
    <DocumentsContextProvider
      customer={
        {
          uuid: outlet?.bpId,
          bpid: outlet?.bpId,
          __typename: 'CustomerBusiness',
        } as Customer
      }
      uploadType={UploadType.Outlet}
    >
      {useNewDocumentsFeature ? (
        <div>New documents here</div>
      ) : (
        <DocumentsOld />
      )}
    </DocumentsContextProvider>
  );
};

export default OutletDocuments;
