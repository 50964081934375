import { Button, Tag, TextInput } from '@smart/react-components';
import {
  BeautifiedOrderDeliveryStatusFilterValues,
  CountryVlcFilterValue,
  CurrentOrderLocationFilterValues,
  FacetSortByValue,
  FacetValue,
  OrderPaymentStatusFilterValues,
  OrderSortByValues,
  OrderStatusFilterValues,
  getOrderPaymentTypeFilterValues,
} from '@ui/data-models/facet/facet.model';
import { MarketCode } from '@utils/market/types';
import { useMarketContext } from 'contexts/market-context';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import SearchFilterPanel from '../../../atoms/SearchFilterPanel/SearchFilterPanel';
import FilterDropdown from '../../FilterDropdown/FilterDropdown';
import FilterDropdownMultiple from '../../FilterDropdownMultiple/FilterDropdownMultiple';
import { useOrdersContext } from '../order-items-context';
import {
  OrderCarLineFilterValues,
  OrderCarModelFilterValues,
} from './Search.config';
import './Search.scss';

const BASE_CLASS = 'adb-orders-search';

const getCurrentOrderLocationFilterValues = (
  market: MarketCode
): FacetValue[] => {
  switch (market) {
    case 'gb':
    case 'it':
    case 'es':
      return [...CurrentOrderLocationFilterValues, CountryVlcFilterValue];
    default:
      return CurrentOrderLocationFilterValues;
  }
};

const defaultSort = {
  displayName: '',
  queryValue: '',
};

const OrdersSearch = () => {
  const { t } = useTranslation();
  const { market } = useMarketContext();
  const { handleQueryUpdate, pagination } = useOrdersContext();
  const [params, setParams] = useSearchParams();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [sortQuery, setSortQuery] = useState<FacetSortByValue>(defaultSort);
  const [filterQueries, setFilterQueries] = useState<FacetValue[]>([]);

  const searchQuery = params.get('search') ?? '';
  const page = params.get('page') ?? '';

  useEffect(() => {
    if (page && setParams) {
      // Reset page on filter/search update to avoid ending up on a page that does not exist
      const newParams = new URLSearchParams(params.toString());
      newParams.delete('page');
      setParams(newParams);
    }
    handleQueryUpdate(sortQuery.queryValue, filterQueries, searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleQueryUpdate, sortQuery.queryValue, filterQueries, searchQuery]);

  // Function format the filter name with translation
  const getFormattedFilterWithTranslation = (
    filters: FacetValue[]
  ): FacetValue[] =>
    filters.map((item) => ({
      ...item,
      displayName: t(`orders.filter.${item.displayName}`),
      label: t(`orders.${item.label}`),
    }));

  const getFormattedFilterWithTranslatedLabel = (
    filters: FacetValue[]
  ): FacetValue[] =>
    filters.map((item) => ({
      ...item,
      label: t(`orders.${item.label}`),
    }));

  const formattedPaymentTypeFilters = getFormattedFilterWithTranslation(
    getOrderPaymentTypeFilterValues(market)
  );

  const formattedPaymentStatusFilters = getFormattedFilterWithTranslation(
    OrderPaymentStatusFilterValues
  );

  const formattedBeautifiedOrderDeliveryStatusFilters =
    getFormattedFilterWithTranslation(
      BeautifiedOrderDeliveryStatusFilterValues
    );

  const formattedOrderStatusFilters = getFormattedFilterWithTranslation(
    OrderStatusFilterValues
  );

  const formattedOrderSortByValuesFilter = OrderSortByValues.map((item) => ({
    displayName: t(`orders.sort.${item.displayName}`),
    queryValue: item.queryValue,
  }));

  // Function to handle sort query
  const handleSortQuery = (sortByValue: FacetSortByValue): void => {
    setSortQuery(sortByValue);
  };

  // Function to handle filter query
  const handleFilterQuery = (filter: FacetValue[]): void => {
    const newFilters = [...filter];
    setFilterQueries(newFilters);
  };

  const clearAllFilters = (): void => {
    const filters: FacetValue[] = [];
    setFilterQueries(filters);
    setSortQuery(defaultSort);
  };

  // Function to handle search query
  const handleSearchQuery = (value: string): void => {
    setParams((prev: URLSearchParams) => {
      if (prev.has('page')) prev.delete('page');

      prev.set('search', value);
      return prev;
    });
  };

  const removeFilter = (filterItem: FacetValue): void => {
    const newFilterQueries = [...filterQueries];
    newFilterQueries.splice(
      filterQueries.findIndex((filter) => filter.name === filterItem.name),
      1
    );
    setFilterQueries(newFilterQueries);
  };

  const hideFilters = () => setShowFilters((prev) => !prev);

  return (
    <>
      <SearchFilterPanel
        searchBar={
          <TextInput
            type="text"
            name="search"
            id="text_input_id"
            onChange={(e) => handleSearchQuery(e.target.value)}
            label={t('orders.search')}
            value={searchQuery}
            caption={`${pagination.totalResults} ${t('general.labels.results')}`}
          />
        }
        showSearch
        filters={
          <>
            <FilterDropdown
              label={t('orders.sort.sort_by')}
              items={formattedOrderSortByValuesFilter}
              onSelect={(filter) => handleSortQuery(filter)}
              selectedFilter={sortQuery}
            />
            <Button
              variant="ghost"
              type="button"
              onClick={hideFilters}
              mode={200}
            >
              {showFilters
                ? t('orders.buttons.hide_filter')
                : t('orders.buttons.show_filter')}
              <Button.Icon icon="filter" aria-label="filter" />
            </Button>
          </>
        }
        darkMode
      />

      {showFilters && (
        <div className={BASE_CLASS}>
          <div className={`${BASE_CLASS}__filters`}>
            <FilterDropdownMultiple
              filterName={t('orders.payment_details.payment_status')}
              filterValues={formattedPaymentStatusFilters}
              onSelect={(filter) => handleFilterQuery(filter)}
              currentFilters={filterQueries}
            />
            <FilterDropdownMultiple
              filterName={t('orders.payment_details.payment_type')}
              filterValues={formattedPaymentTypeFilters}
              onSelect={(filter) => handleFilterQuery(filter)}
              currentFilters={filterQueries}
            />
            <FilterDropdownMultiple
              filterName={t('orders.beautified_delivery_status')}
              filterValues={formattedBeautifiedOrderDeliveryStatusFilters}
              onSelect={(filter) => handleFilterQuery(filter)}
              currentFilters={filterQueries}
            />
            <FilterDropdownMultiple
              filterName={t('orders.current_location')}
              filterValues={getFormattedFilterWithTranslatedLabel(
                getCurrentOrderLocationFilterValues(market)
              )}
              onSelect={(filter) => handleFilterQuery(filter)}
              currentFilters={filterQueries}
            />
            <FilterDropdownMultiple
              filterName={t('orders.vehicle_model')}
              filterValues={getFormattedFilterWithTranslatedLabel(
                OrderCarModelFilterValues
              )}
              onSelect={(filter) => handleFilterQuery(filter)}
              currentFilters={filterQueries}
            />
            <FilterDropdownMultiple
              filterName={t('orders.vehicle_line')}
              filterValues={getFormattedFilterWithTranslatedLabel(
                OrderCarLineFilterValues
              )}
              onSelect={(filter) => handleFilterQuery(filter)}
              currentFilters={filterQueries}
            />
            <FilterDropdownMultiple
              filterName={t('orders.order_status_label')}
              filterValues={formattedOrderStatusFilters}
              onSelect={(filter) => handleFilterQuery(filter)}
              currentFilters={filterQueries}
            />
          </div>
        </div>
      )}

      {(filterQueries.length > 0 ||
        (sortQuery.displayName !== '' && sortQuery.queryValue !== '')) && (
        <div className={`${BASE_CLASS}__tags`}>
          {filterQueries.map((filter) => (
            <div className={`${BASE_CLASS}__tags-item`} key={filter.queryValue}>
              <Tag
                open
                onOpenChange={() => removeFilter(filter)}
                key={filter.displayName}
              >
                {`${filter?.label}: ${filter.displayName}`}
                <Tag.Close aria-label={`remove filter ${filter.displayName}`} />
              </Tag>
            </div>
          ))}
          {sortQuery.displayName !== '' && sortQuery.queryValue !== '' && (
            <div className={`${BASE_CLASS}__tags-item`}>
              <Tag
                open
                onOpenChange={() => handleSortQuery(defaultSort)}
                key={sortQuery.displayName}
              >
                `${t('orders.sort.sort_by')}: ${sortQuery.displayName}`
                <Tag.Close
                  aria-label={`remove sort ${sortQuery.displayName}`}
                />
              </Tag>
            </div>
          )}
          <div className={`${BASE_CLASS}__tags-item`} key="clear-all">
            <Tag
              open
              className={`${BASE_CLASS}__tags-item-clear-all`}
              onOpenChange={() => clearAllFilters()}
              key="clear-all"
            >
              {t('orders.filter.clear_all')}
              <Tag.Close aria-label="remove all filters" />
            </Tag>
          </div>
        </div>
      )}
    </>
  );
};

export default OrdersSearch;
