import {
  BusinessAdditionalInfo as BusinessAdditionalInfoI,
  Maybe,
} from '@smart/adb-shared';
import ErrorField from '@smart/components-adb/atoms/ErrorField/ErrorField';
import Section from '@smart/components-adb/atoms/Section/Section';
import { Select, TextInput } from '@smart/react-components';
import useSelect from '@utils/hooks/useSelect';
import { useMarketContext } from 'contexts/market-context';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  BusinessAdditionalInfoFormValues,
  CustomerForm,
  CustomerFormTypes,
} from '../helper';
import { usePreferredOptionsQuery } from '../queries.generated';
import BusinessAdditionalInfoSchema from './BusinessAdditionalInfoSchema';

const BASE_CLASS = 'adb-details';

type BusinessAdditionalInfoProps = CustomerForm & {
  businessAdditionalDetails: Maybe<BusinessAdditionalInfoI> | undefined;
};

const BusinessAdditionalInfo = ({
  businessAdditionalDetails,
  handleCustomerDetailSubmit,
  formFooter,
  hasExpanded,
}: BusinessAdditionalInfoProps) => {
  const { t } = useTranslation();
  const { getTranslatedFilters } = useSelect();
  const { market } = useMarketContext();

  const { data } = usePreferredOptionsQuery();

  const amountOfCarsOptions = data?.preferredOptions.amountOfCars ?? [];
  const daimlerFrameworkAgreementOptions =
    data?.preferredOptions.daimlerFrameworkAgreement ?? [];
  const fleetSizeOptions = data?.preferredOptions.fleetSize ?? [];

  const defaultFleetSize = fleetSizeOptions.length
    ? fleetSizeOptions[0].value
    : '';

  const defaultDaimlerFrameworkAgreement =
    daimlerFrameworkAgreementOptions.length
      ? daimlerFrameworkAgreementOptions[0].value
      : '';

  const defaultAmountOfCarsOptions = amountOfCarsOptions.length
    ? amountOfCarsOptions[0].value
    : '';

  const initialBusinessAdditionalInfo = {
    daimlerFrameworkAgreement:
      businessAdditionalDetails?.daimlerFrameworkAgreement ??
      defaultDaimlerFrameworkAgreement,
    frameworkAgreementNumber:
      businessAdditionalDetails?.frameworkAgreementNumber ?? '',
    fleetSize: businessAdditionalDetails?.fleetSize ?? defaultFleetSize,
    amountCars:
      businessAdditionalDetails?.amountCars ?? defaultAmountOfCarsOptions,
  };

  const validationSchema = BusinessAdditionalInfoSchema(market?.toUpperCase());

  const onSubmit = (values: BusinessAdditionalInfoFormValues) => {
    handleCustomerDetailSubmit(
      values,
      CustomerFormTypes.ADDITIONAL_INFO_BUSINESS
    );
  };

  return (
    <Formik
      initialValues={initialBusinessAdditionalInfo}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values,
        touched,
        errors,
        dirty,
        setFieldValue,
        handleSubmit,
        handleReset,
      }: FormikProps<BusinessAdditionalInfoFormValues>) => (
        <Form autoComplete="on" onSubmit={handleSubmit} onReset={handleReset}>
          <Section
            expanded={!!hasExpanded}
            title={t('customer.details.additional_information')}
            hasAccordion
          >
            <div className={`${BASE_CLASS}__input-wrap`}>
              <div
                className={`${BASE_CLASS}__form-wrap ${BASE_CLASS}__form-wrap--two-columns-1-1`}
              >
                <div className={`${BASE_CLASS}__form-input`}>
                  <Select
                    id="daimler_framework_agreement"
                    name="daimlerFrameworkAgreement"
                    label={t(
                      'form_fields.business.daimler_framework_agreement'
                    )}
                    value={values.daimlerFrameworkAgreement}
                    onSelectionChange={(value) =>
                      setFieldValue('daimlerFrameworkAgreement', value, true)
                    }
                    items={getTranslatedFilters(
                      daimlerFrameworkAgreementOptions ?? [],
                      'daimler_framework_agreement'
                    )}
                  >
                    {(items) =>
                      items.map((item) => (
                        <Select.Item key={item.value} {...item} />
                      ))
                    }
                  </Select>
                  {errors.daimlerFrameworkAgreement &&
                    touched.daimlerFrameworkAgreement && (
                      <ErrorField errorMsg={errors.daimlerFrameworkAgreement} />
                    )}
                </div>
                <div className={`${BASE_CLASS}__form-input`}>
                  <TextInput
                    id="framework_agreement_number"
                    type="text"
                    name="frameworkAgreementNumber"
                    key="frameworkAgreementNumber"
                    value={values.frameworkAgreementNumber}
                    label={t('form_fields.business.framework_agreement')}
                    disabled
                  />
                  {errors.frameworkAgreementNumber &&
                    touched.frameworkAgreementNumber && (
                      <ErrorField errorMsg={errors.frameworkAgreementNumber} />
                    )}
                </div>
              </div>
              <div
                className={`${BASE_CLASS}__form-wrap ${BASE_CLASS}__form-wrap--two-columns-1-1`}
              >
                <div className={`${BASE_CLASS}__form-input`}>
                  <Select
                    id="fleet_size"
                    name="fleetSize"
                    label={t('form_fields.business.fleet_size')}
                    value={values.fleetSize}
                    onSelectionChange={(value) =>
                      setFieldValue('fleetSize', value, true)
                    }
                    items={getTranslatedFilters(
                      fleetSizeOptions ?? [],
                      'amount_of_cars'
                    )}
                  >
                    {(items) =>
                      items.map((item) => (
                        <Select.Item key={item.value} {...item} />
                      ))
                    }
                  </Select>
                  {errors.fleetSize && touched.fleetSize && (
                    <ErrorField errorMsg={errors.fleetSize} />
                  )}
                </div>
                <div className={`${BASE_CLASS}__form-input`}>
                  <Select
                    id="amount_cars"
                    name="amountCars"
                    label={t('form_fields.business.amount_of_cars')}
                    value={values.amountCars}
                    onSelectionChange={(value) =>
                      setFieldValue('amountCars', value, true)
                    }
                    items={getTranslatedFilters(
                      amountOfCarsOptions,
                      'amount_of_cars'
                    )}
                  >
                    {(items) =>
                      items.map((item) => (
                        <Select.Item key={item.value} {...item} />
                      ))
                    }
                  </Select>
                  {errors.amountCars && touched.amountCars && (
                    <ErrorField errorMsg={errors.amountCars} />
                  )}
                </div>
              </div>
            </div>
            {formFooter(CustomerFormTypes.ADDITIONAL_INFO_BUSINESS, !dirty)}
          </Section>
        </Form>
      )}
    </Formik>
  );
};

export default BusinessAdditionalInfo;
