/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllOrdersQueryVariables = Types.Exact<{
  input: Types.AllOrdersInput;
}>;


export type AllOrdersQuery = { __typename?: 'Query', allOrders: { __typename?: 'AllOrdersResponse', orders: Array<{ __typename?: 'OrderSummary', code: string, beautifiedDeliveryStatus?: string | null, customerName: string, deliveryDate?: string | null, deliveryLocation?: string | null, deliveryStatus?: string | null, lineCode?: string | null, paymentStatus?: Types.OrderPaymentStatus | null, paymentType: Types.OrderPaymentType, productCode?: string | null, productName?: string | null, requestedDeliveryDate?: string | null, status?: Types.OrderStatus | null, aldJourneyStatus?: Types.AldJourneyStatus | null, vin?: string | null, orderType?: Types.OrderType | null, extaStatus?: string | null }>, pagination: { __typename?: 'Pagination', currentPage?: number | null, pageSize?: number | null, totalPages?: number | null, totalResults?: number | null } } };


export const AllOrdersDocument = gql`
    query AllOrders($input: AllOrdersInput!) {
  allOrders(input: $input) {
    orders {
      code
      beautifiedDeliveryStatus
      customerName
      deliveryDate
      deliveryLocation
      deliveryStatus
      lineCode
      paymentStatus
      paymentType
      productCode
      productName
      requestedDeliveryDate
      status
      aldJourneyStatus
      vin
      orderType
      extaStatus
    }
    pagination {
      currentPage
      pageSize
      totalPages
      totalResults
    }
  }
}
    `;

/**
 * __useAllOrdersQuery__
 *
 * To run a query within a React component, call `useAllOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOrdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAllOrdersQuery(baseOptions: Apollo.QueryHookOptions<AllOrdersQuery, AllOrdersQueryVariables> & ({ variables: AllOrdersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllOrdersQuery, AllOrdersQueryVariables>(AllOrdersDocument, options);
      }
export function useAllOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllOrdersQuery, AllOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllOrdersQuery, AllOrdersQueryVariables>(AllOrdersDocument, options);
        }
export function useAllOrdersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllOrdersQuery, AllOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllOrdersQuery, AllOrdersQueryVariables>(AllOrdersDocument, options);
        }
export type AllOrdersQueryHookResult = ReturnType<typeof useAllOrdersQuery>;
export type AllOrdersLazyQueryHookResult = ReturnType<typeof useAllOrdersLazyQuery>;
export type AllOrdersSuspenseQueryHookResult = ReturnType<typeof useAllOrdersSuspenseQuery>;
export type AllOrdersQueryResult = Apollo.QueryResult<AllOrdersQuery, AllOrdersQueryVariables>;