import { useTranslation } from 'react-i18next';
import { number } from 'yup';

export const FrameworkAgreementNumberSchema = (
  validationMarket: string,
  required = false
) => {
  const { t } = useTranslation();

  let schemaObject = {};

  if (validationMarket) {
    switch (validationMarket) {
      case 'DE':
        schemaObject = {
          frameworkAgreementNumber: required
            ? number()
                .transform((value, originalValue) =>
                  /\s/.test(originalValue) ? NaN : value
                )
                .typeError(
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.business.framework_agreement'
                  )}`
                )
                .required(
                  `${t('form_fields.business.framework_agreement')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`
                )
            : number()
                .transform((value, originalValue) =>
                  /\s/.test(originalValue) ? NaN : value
                )
                .typeError(
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.business.framework_agreement'
                  )} `
                ),
        };
        break;
      default:
        schemaObject = {
          frameworkAgreementNumber: required
            ? number()
                .transform((value, originalValue) =>
                  /\s/.test(originalValue) ? NaN : value
                )
                .typeError(
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.business.framework_agreement'
                  )} `
                )
                .required(
                  `${t('form_fields.business.framework_agreement')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`
                )
            : number()
                .transform((value, originalValue) =>
                  /\s/.test(originalValue) ? NaN : value
                )
                .typeError(
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.business.framework_agreement'
                  )} `
                ),
        };
        break;
    }
  }

  return schemaObject;
};
