import { CompanyInfo, Maybe } from '@smart/adb-shared';
import ErrorField from '@smart/components-adb/atoms/ErrorField/ErrorField';
import Section from '@smart/components-adb/atoms/Section/Section';
import { TextInput } from '@smart/react-components';
import { useMarketContext } from 'contexts/market-context';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  BusinessCompanyInfoFormValues,
  CustomerForm,
  CustomerFormTypes,
} from '../helper';
import BusinessCompanyInfoSchema from './BusinessCompanyInfoSchema';

const BASE_CLASS = 'adb-details';

type BusinessCompanyInfoProps = CustomerForm & {
  businessCompanyDetails: Maybe<CompanyInfo> | undefined;
};

const BusinessCompanyInfo = ({
  businessCompanyDetails,
  handleCustomerDetailSubmit,
  formFooter,
  hasExpanded,
}: BusinessCompanyInfoProps) => {
  const { t } = useTranslation();
  const { market } = useMarketContext();

  const initialBusinessCompanyInfo: BusinessCompanyInfoFormValues = {
    legalForm: businessCompanyDetails?.legalForm ?? '',
    commercialRegisterNo: businessCompanyDetails?.commercialRegisterNo ?? '',
    documentCheck: businessCompanyDetails?.documentCheck ?? '',
  };

  const onSubmit = (values: BusinessCompanyInfoFormValues) => {
    handleCustomerDetailSubmit(values, CustomerFormTypes.COMPANY_INFO_BUSINESS);
  };

  const validationSchema = BusinessCompanyInfoSchema(market.toUpperCase());

  return (
    <Formik
      initialValues={initialBusinessCompanyInfo}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values,
        touched,
        errors,
        dirty,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
      }: FormikProps<BusinessCompanyInfoFormValues>) => (
        <Form autoComplete="on" onSubmit={handleSubmit} onReset={handleReset}>
          <Section
            expanded={!!hasExpanded}
            title={t('customer.details.company_information')}
            hasAccordion
          >
            <div className={`${BASE_CLASS}__input-wrap`}>
              <div className={`${BASE_CLASS}__form-wrap`}>
                <div className={`${BASE_CLASS}__form-input`}>
                  <TextInput
                    id="legal_form"
                    type="text"
                    name="legalForm"
                    value={values?.legalForm}
                    label={t('form_fields.company.legal_form')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors?.legalForm && touched?.legalForm && (
                    <ErrorField errorMsg={errors?.legalForm} />
                  )}
                </div>
                <div className={`${BASE_CLASS}__form-input`}>
                  <TextInput
                    id="commercial_register_nr"
                    type="text"
                    name="commercialRegisterNo"
                    value={values?.commercialRegisterNo}
                    label={t('form_fields.company.commercial_registerNr')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors?.commercialRegisterNo &&
                    touched?.commercialRegisterNo && (
                      <ErrorField errorMsg={errors?.commercialRegisterNo} />
                    )}
                </div>
                <div className={`${BASE_CLASS}__form-input`}>
                  <TextInput
                    id="document_check"
                    type="text"
                    name="documentCheck"
                    value={values?.documentCheck}
                    label={t('form_fields.company.document_check')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                  />
                  {errors?.documentCheck && touched?.documentCheck && (
                    <ErrorField errorMsg={errors?.documentCheck} />
                  )}
                </div>
              </div>
            </div>
            {formFooter(CustomerFormTypes.COMPANY_INFO_BUSINESS, !dirty)}
          </Section>
        </Form>
      )}
    </Formik>
  );
};

export default BusinessCompanyInfo;
