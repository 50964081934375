/* eslint-disable */
// @ts-nocheck
import * as Types from '@smart/adb-shared';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TaskFragmentFragment = { __typename?: 'Task', id: string, cartId?: string | null, title: string, description?: string | null, activityDate?: string | null, requestedDate?: string | null, scheduledDate?: string | null, createdDate: string, type?: Types.TaskType | null, subtype?: Types.TaskSubtype | null, status?: Types.TaskStatus | null, step?: Types.TaskStep | null, vin?: string | null, orderNumber?: string | null, productInterest?: string | null, leadSource?: string | null, callAttempts?: number | null, lastContacted?: string | null, opportunityId?: string | null, isAccountAssigned: boolean, isAgentAssigned: boolean, requestCompletedDate?: string | null, requestCreatedDate?: string | null, identificationDocument?: string | null, identificationType?: string | null, vehicle?: { __typename?: 'TaskVehicle', id: string, interior?: string | null, exterior?: string | null, model?: string | null, productName?: string | null, licensePlate?: string | null } | null, customer: { __typename?: 'TaskBusinessCustomer', firstName: string, lastName: string, uuid?: string | null, userId: string, bpid?: string | null, sfCustomerId?: string | null, sfOrgId?: string | null, type?: Types.CustomerAccountType | null, phoneNumber?: string | null, postalCode?: string | null, regType?: Types.CustomerRegType | null } | { __typename?: 'TaskPrivateCustomer', firstName: string, lastName: string, uuid?: string | null, userId: string, sfCustomerId?: string | null, type?: Types.CustomerAccountType | null, phoneNumber?: string | null, postalCode?: string | null, regType?: Types.CustomerRegType | null }, agent: { __typename?: 'TaskAgent', sfId: string, fullName: string, gigyaId?: string | null }, appointment?: { __typename?: 'TaskAppointment', id: string, startTime?: string | null, endTime?: string | null, createdTime?: string | null, status?: string | null } | null };

export type AllTasksQueryVariables = Types.Exact<{
  input: Types.AllTasksInput;
}>;


export type AllTasksQuery = { __typename?: 'Query', allTasks: { __typename?: 'AllTasksResponse', tasks: Array<{ __typename?: 'Task', id: string, cartId?: string | null, title: string, description?: string | null, activityDate?: string | null, requestedDate?: string | null, scheduledDate?: string | null, createdDate: string, type?: Types.TaskType | null, subtype?: Types.TaskSubtype | null, status?: Types.TaskStatus | null, step?: Types.TaskStep | null, vin?: string | null, orderNumber?: string | null, productInterest?: string | null, leadSource?: string | null, callAttempts?: number | null, lastContacted?: string | null, opportunityId?: string | null, isAccountAssigned: boolean, isAgentAssigned: boolean, requestCompletedDate?: string | null, requestCreatedDate?: string | null, identificationDocument?: string | null, identificationType?: string | null, vehicle?: { __typename?: 'TaskVehicle', id: string, interior?: string | null, exterior?: string | null, model?: string | null, productName?: string | null, licensePlate?: string | null } | null, customer: { __typename?: 'TaskBusinessCustomer', firstName: string, lastName: string, uuid?: string | null, userId: string, bpid?: string | null, sfCustomerId?: string | null, sfOrgId?: string | null, type?: Types.CustomerAccountType | null, phoneNumber?: string | null, postalCode?: string | null, regType?: Types.CustomerRegType | null } | { __typename?: 'TaskPrivateCustomer', firstName: string, lastName: string, uuid?: string | null, userId: string, sfCustomerId?: string | null, type?: Types.CustomerAccountType | null, phoneNumber?: string | null, postalCode?: string | null, regType?: Types.CustomerRegType | null }, agent: { __typename?: 'TaskAgent', sfId: string, fullName: string, gigyaId?: string | null }, appointment?: { __typename?: 'TaskAppointment', id: string, startTime?: string | null, endTime?: string | null, createdTime?: string | null, status?: string | null } | null }> } };

export type TaskQueryVariables = Types.Exact<{
  input: Types.TaskInput;
}>;


export type TaskQuery = { __typename?: 'Query', task: { __typename?: 'Task', id: string, cartId?: string | null, title: string, description?: string | null, activityDate?: string | null, requestedDate?: string | null, scheduledDate?: string | null, createdDate: string, type?: Types.TaskType | null, subtype?: Types.TaskSubtype | null, status?: Types.TaskStatus | null, step?: Types.TaskStep | null, vin?: string | null, orderNumber?: string | null, productInterest?: string | null, leadSource?: string | null, callAttempts?: number | null, lastContacted?: string | null, opportunityId?: string | null, isAccountAssigned: boolean, isAgentAssigned: boolean, requestCompletedDate?: string | null, requestCreatedDate?: string | null, identificationDocument?: string | null, identificationType?: string | null, vehicle?: { __typename?: 'TaskVehicle', id: string, interior?: string | null, exterior?: string | null, model?: string | null, productName?: string | null, licensePlate?: string | null } | null, customer: { __typename?: 'TaskBusinessCustomer', firstName: string, lastName: string, uuid?: string | null, userId: string, bpid?: string | null, sfCustomerId?: string | null, sfOrgId?: string | null, type?: Types.CustomerAccountType | null, phoneNumber?: string | null, postalCode?: string | null, regType?: Types.CustomerRegType | null } | { __typename?: 'TaskPrivateCustomer', firstName: string, lastName: string, uuid?: string | null, userId: string, sfCustomerId?: string | null, type?: Types.CustomerAccountType | null, phoneNumber?: string | null, postalCode?: string | null, regType?: Types.CustomerRegType | null }, agent: { __typename?: 'TaskAgent', sfId: string, fullName: string, gigyaId?: string | null }, appointment?: { __typename?: 'TaskAppointment', id: string, startTime?: string | null, endTime?: string | null, createdTime?: string | null, status?: string | null } | null } };

export type AllTasksSimpleQueryVariables = Types.Exact<{
  input: Types.AllTasksInput;
}>;


export type AllTasksSimpleQuery = { __typename?: 'Query', allTasks: { __typename?: 'AllTasksResponse', tasks: Array<{ __typename?: 'Task', id: string, cartId?: string | null, title: string, requestedDate?: string | null, scheduledDate?: string | null, createdDate: string, status?: Types.TaskStatus | null, type?: Types.TaskType | null, isAccountAssigned: boolean, isAgentAssigned: boolean, customer: { __typename?: 'TaskBusinessCustomer', firstName: string, lastName: string, uuid?: string | null, userId: string, bpid?: string | null, sfCustomerId?: string | null, sfOrgId?: string | null, type?: Types.CustomerAccountType | null, phoneNumber?: string | null, postalCode?: string | null, regType?: Types.CustomerRegType | null } | { __typename?: 'TaskPrivateCustomer', firstName: string, lastName: string, uuid?: string | null, userId: string, sfCustomerId?: string | null, type?: Types.CustomerAccountType | null, phoneNumber?: string | null, postalCode?: string | null, regType?: Types.CustomerRegType | null }, agent: { __typename?: 'TaskAgent', sfId: string, fullName: string, gigyaId?: string | null } }> } };

export type ChangeOwnerMutationVariables = Types.Exact<{
  input: Types.ChangeOwnerInput;
}>;


export type ChangeOwnerMutation = { __typename?: 'Mutation', changeOwner: { __typename?: 'TaskUpdateResponse', success: boolean, message: string, taskId: string } };

export type CloseTaskMutationVariables = Types.Exact<{
  input: Types.CloseTaskInput;
}>;


export type CloseTaskMutation = { __typename?: 'Mutation', closeTask: { __typename?: 'TaskUpdateResponse', success: boolean, message: string, taskId: string } };

export type CallAttemptMutationVariables = Types.Exact<{
  input: Types.CallAttemptInput;
}>;


export type CallAttemptMutation = { __typename?: 'Mutation', callAttempt: { __typename?: 'TaskUpdateResponse', success: boolean, message: string, taskId: string } };

export type RescheduleMutationVariables = Types.Exact<{
  input: Types.RescheduleInput;
}>;


export type RescheduleMutation = { __typename?: 'Mutation', reschedule: { __typename?: 'TaskUpdateResponse', success: boolean, message: string, taskId: string } };

export const TaskFragmentFragmentDoc = gql`
    fragment TaskFragment on Task {
  id
  cartId
  title
  description
  activityDate
  requestedDate
  scheduledDate
  createdDate
  type
  subtype
  status
  step
  vin
  orderNumber
  productInterest
  leadSource
  callAttempts
  lastContacted
  opportunityId
  isAccountAssigned
  vehicle {
    id
    interior
    exterior
    model
    productName
    licensePlate
  }
  customer {
    ... on TaskPrivateCustomer {
      firstName
      lastName
      uuid
      userId
      sfCustomerId
      type
      phoneNumber
      postalCode
      regType
    }
    ... on TaskBusinessCustomer {
      firstName
      lastName
      uuid
      userId
      bpid
      sfCustomerId
      sfOrgId
      type
      phoneNumber
      postalCode
      regType
    }
  }
  agent {
    sfId
    fullName
    gigyaId
  }
  isAgentAssigned
  appointment {
    id
    startTime
    endTime
    createdTime
    status
  }
  requestCompletedDate
  requestCreatedDate
  identificationDocument
  identificationType
}
    `;
export const AllTasksDocument = gql`
    query AllTasks($input: AllTasksInput!) {
  allTasks(input: $input) {
    tasks {
      ...TaskFragment
    }
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useAllTasksQuery__
 *
 * To run a query within a React component, call `useAllTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTasksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAllTasksQuery(baseOptions: Apollo.QueryHookOptions<AllTasksQuery, AllTasksQueryVariables> & ({ variables: AllTasksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTasksQuery, AllTasksQueryVariables>(AllTasksDocument, options);
      }
export function useAllTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTasksQuery, AllTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTasksQuery, AllTasksQueryVariables>(AllTasksDocument, options);
        }
export function useAllTasksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllTasksQuery, AllTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllTasksQuery, AllTasksQueryVariables>(AllTasksDocument, options);
        }
export type AllTasksQueryHookResult = ReturnType<typeof useAllTasksQuery>;
export type AllTasksLazyQueryHookResult = ReturnType<typeof useAllTasksLazyQuery>;
export type AllTasksSuspenseQueryHookResult = ReturnType<typeof useAllTasksSuspenseQuery>;
export type AllTasksQueryResult = Apollo.QueryResult<AllTasksQuery, AllTasksQueryVariables>;
export const TaskDocument = gql`
    query Task($input: TaskInput!) {
  task(input: $input) {
    ...TaskFragment
  }
}
    ${TaskFragmentFragmentDoc}`;

/**
 * __useTaskQuery__
 *
 * To run a query within a React component, call `useTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskQuery(baseOptions: Apollo.QueryHookOptions<TaskQuery, TaskQueryVariables> & ({ variables: TaskQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
      }
export function useTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskQuery, TaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
        }
export function useTaskSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TaskQuery, TaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
        }
export type TaskQueryHookResult = ReturnType<typeof useTaskQuery>;
export type TaskLazyQueryHookResult = ReturnType<typeof useTaskLazyQuery>;
export type TaskSuspenseQueryHookResult = ReturnType<typeof useTaskSuspenseQuery>;
export type TaskQueryResult = Apollo.QueryResult<TaskQuery, TaskQueryVariables>;
export const AllTasksSimpleDocument = gql`
    query AllTasksSimple($input: AllTasksInput!) {
  allTasks(input: $input) {
    tasks {
      id
      cartId
      title
      requestedDate
      scheduledDate
      createdDate
      status
      type
      isAccountAssigned
      customer {
        ... on TaskPrivateCustomer {
          firstName
          lastName
          uuid
          userId
          sfCustomerId
          type
          phoneNumber
          postalCode
          regType
        }
        ... on TaskBusinessCustomer {
          firstName
          lastName
          uuid
          userId
          bpid
          sfCustomerId
          sfOrgId
          type
          phoneNumber
          postalCode
          regType
        }
      }
      agent {
        sfId
        fullName
        gigyaId
      }
      isAgentAssigned
    }
  }
}
    `;

/**
 * __useAllTasksSimpleQuery__
 *
 * To run a query within a React component, call `useAllTasksSimpleQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTasksSimpleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTasksSimpleQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAllTasksSimpleQuery(baseOptions: Apollo.QueryHookOptions<AllTasksSimpleQuery, AllTasksSimpleQueryVariables> & ({ variables: AllTasksSimpleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTasksSimpleQuery, AllTasksSimpleQueryVariables>(AllTasksSimpleDocument, options);
      }
export function useAllTasksSimpleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTasksSimpleQuery, AllTasksSimpleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTasksSimpleQuery, AllTasksSimpleQueryVariables>(AllTasksSimpleDocument, options);
        }
export function useAllTasksSimpleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllTasksSimpleQuery, AllTasksSimpleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllTasksSimpleQuery, AllTasksSimpleQueryVariables>(AllTasksSimpleDocument, options);
        }
export type AllTasksSimpleQueryHookResult = ReturnType<typeof useAllTasksSimpleQuery>;
export type AllTasksSimpleLazyQueryHookResult = ReturnType<typeof useAllTasksSimpleLazyQuery>;
export type AllTasksSimpleSuspenseQueryHookResult = ReturnType<typeof useAllTasksSimpleSuspenseQuery>;
export type AllTasksSimpleQueryResult = Apollo.QueryResult<AllTasksSimpleQuery, AllTasksSimpleQueryVariables>;
export const ChangeOwnerDocument = gql`
    mutation ChangeOwner($input: ChangeOwnerInput!) {
  changeOwner(input: $input) {
    success
    message
    taskId
  }
}
    `;
export type ChangeOwnerMutationFn = Apollo.MutationFunction<ChangeOwnerMutation, ChangeOwnerMutationVariables>;

/**
 * __useChangeOwnerMutation__
 *
 * To run a mutation, you first call `useChangeOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeOwnerMutation, { data, loading, error }] = useChangeOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeOwnerMutation(baseOptions?: Apollo.MutationHookOptions<ChangeOwnerMutation, ChangeOwnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeOwnerMutation, ChangeOwnerMutationVariables>(ChangeOwnerDocument, options);
      }
export type ChangeOwnerMutationHookResult = ReturnType<typeof useChangeOwnerMutation>;
export type ChangeOwnerMutationResult = Apollo.MutationResult<ChangeOwnerMutation>;
export type ChangeOwnerMutationOptions = Apollo.BaseMutationOptions<ChangeOwnerMutation, ChangeOwnerMutationVariables>;
export const CloseTaskDocument = gql`
    mutation CloseTask($input: CloseTaskInput!) {
  closeTask(input: $input) {
    success
    message
    taskId
  }
}
    `;
export type CloseTaskMutationFn = Apollo.MutationFunction<CloseTaskMutation, CloseTaskMutationVariables>;

/**
 * __useCloseTaskMutation__
 *
 * To run a mutation, you first call `useCloseTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeTaskMutation, { data, loading, error }] = useCloseTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloseTaskMutation(baseOptions?: Apollo.MutationHookOptions<CloseTaskMutation, CloseTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseTaskMutation, CloseTaskMutationVariables>(CloseTaskDocument, options);
      }
export type CloseTaskMutationHookResult = ReturnType<typeof useCloseTaskMutation>;
export type CloseTaskMutationResult = Apollo.MutationResult<CloseTaskMutation>;
export type CloseTaskMutationOptions = Apollo.BaseMutationOptions<CloseTaskMutation, CloseTaskMutationVariables>;
export const CallAttemptDocument = gql`
    mutation CallAttempt($input: CallAttemptInput!) {
  callAttempt(input: $input) {
    success
    message
    taskId
  }
}
    `;
export type CallAttemptMutationFn = Apollo.MutationFunction<CallAttemptMutation, CallAttemptMutationVariables>;

/**
 * __useCallAttemptMutation__
 *
 * To run a mutation, you first call `useCallAttemptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallAttemptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callAttemptMutation, { data, loading, error }] = useCallAttemptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallAttemptMutation(baseOptions?: Apollo.MutationHookOptions<CallAttemptMutation, CallAttemptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CallAttemptMutation, CallAttemptMutationVariables>(CallAttemptDocument, options);
      }
export type CallAttemptMutationHookResult = ReturnType<typeof useCallAttemptMutation>;
export type CallAttemptMutationResult = Apollo.MutationResult<CallAttemptMutation>;
export type CallAttemptMutationOptions = Apollo.BaseMutationOptions<CallAttemptMutation, CallAttemptMutationVariables>;
export const RescheduleDocument = gql`
    mutation Reschedule($input: RescheduleInput!) {
  reschedule(input: $input) {
    success
    message
    taskId
  }
}
    `;
export type RescheduleMutationFn = Apollo.MutationFunction<RescheduleMutation, RescheduleMutationVariables>;

/**
 * __useRescheduleMutation__
 *
 * To run a mutation, you first call `useRescheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleMutation, { data, loading, error }] = useRescheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleMutation(baseOptions?: Apollo.MutationHookOptions<RescheduleMutation, RescheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RescheduleMutation, RescheduleMutationVariables>(RescheduleDocument, options);
      }
export type RescheduleMutationHookResult = ReturnType<typeof useRescheduleMutation>;
export type RescheduleMutationResult = Apollo.MutationResult<RescheduleMutation>;
export type RescheduleMutationOptions = Apollo.BaseMutationOptions<RescheduleMutation, RescheduleMutationVariables>;