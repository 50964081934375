import { Maybe, PrimaryContactInfo } from '@smart/adb-shared';
import ErrorField from '@smart/components-adb/atoms/ErrorField/ErrorField';
import Section from '@smart/components-adb/atoms/Section/Section';
import { TextInput } from '@smart/react-components';
import { useMarketContext } from 'contexts/market-context';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  BusinessPrimaryContactInfoFormValues,
  CustomerForm,
  CustomerFormTypes,
} from '../helper';
import PrimaryContactInfoSchema from './BusinessPrimaryContactSchema';

const BASE_CLASS = 'adb-details';

type BusinessPrimaryContactInfoProps = CustomerForm & {
  businessPrimaryContactDetails: Maybe<PrimaryContactInfo> | undefined;
};

const BusinessPrimaryContactInfo = ({
  businessPrimaryContactDetails,
  handleCustomerDetailSubmit,
  formFooter,
  hasExpanded,
}: BusinessPrimaryContactInfoProps) => {
  const { t } = useTranslation();
  const { market } = useMarketContext();

  const initialBusinessPrimaryContactDetails = {
    position: businessPrimaryContactDetails?.position ?? '',
  };

  const validationSchema = PrimaryContactInfoSchema(market?.toUpperCase());

  const onSubmit = (values: BusinessPrimaryContactInfoFormValues) => {
    handleCustomerDetailSubmit(values, CustomerFormTypes.CONTACT_INFO_BUSINESS);
  };

  return (
    <Formik
      initialValues={initialBusinessPrimaryContactDetails}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values,
        touched,
        errors,
        dirty,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
      }: FormikProps<BusinessPrimaryContactInfoFormValues>) => (
        <Form autoComplete="on" onSubmit={handleSubmit} onReset={handleReset}>
          <Section
            expanded={!!hasExpanded}
            title={t('customer.details.primary_contact_person')}
            hasAccordion
          >
            <div className={`${BASE_CLASS}__form-wrap`}>
              <div className={`${BASE_CLASS}__form-input`}>
                <TextInput
                  id="position"
                  type="text"
                  name="position"
                  key="position"
                  value={values.position}
                  label={t('form_fields.primary_contact_person.position')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.position && touched.position && (
                  <ErrorField errorMsg={errors.position} />
                )}
              </div>
            </div>
            {formFooter(CustomerFormTypes.CONTACT_INFO_BUSINESS, !dirty)}
          </Section>
        </Form>
      )}
    </Formik>
  );
};

export default BusinessPrimaryContactInfo;
