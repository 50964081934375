import { useTranslation } from 'react-i18next';
import { string } from 'yup';
import { GetMaxLengthValidationMsg, MarketCode } from '../helpers/utils';

export const LastNameSchema = (
  validationMarket: MarketCode,
  required = false
) => {
  const { t } = useTranslation();

  const lastNameRegex =
    /^(?=.{2,40}$)[^-\s¡?÷¿\\ˆ~!@#$%^&()_+=[\]{};:"|/,.<>?¢0123456789]{2,20}([' -]{1}[^-\s¡?÷¿\\ˆ~!@#$%^&()_+=[\]{};:"|/,.<>?¢0123456789]{1,20}){0,}$/g;

  let schemaObject = {};

  if (validationMarket) {
    switch (validationMarket) {
      case 'de':
        schemaObject = {
          lastName: required
            ? string()
                .required(
                  `${t('form_fields.basic.last_name')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`
                )
                .matches(
                  lastNameRegex,
                  `${t('form_fields.basic.last_name')} ${t(
                    'general.error_messages.validations.invalid'
                  )}`
                )
                .max(
                  40,
                  GetMaxLengthValidationMsg(
                    t('form_fields.basic.last_name'),
                    40
                  )
                )
            : string()
                .matches(
                  lastNameRegex,
                  `${t('form_fields.basic.last_name')} ${t(
                    'general.error_messages.validations.invalid'
                  )}`
                )
                .max(
                  40,
                  GetMaxLengthValidationMsg(
                    t('form_fields.basic.last_name'),
                    40
                  )
                ),
        };
        break;
      default:
        schemaObject = {
          lastName: required
            ? string()
                .required(
                  `${t('form_fields.basic.last_name')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`
                )
                .matches(
                  lastNameRegex,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.basic.last_name'
                  )} `
                )
                .max(
                  40,
                  GetMaxLengthValidationMsg(
                    t('form_fields.basic.last_name'),
                    40
                  )
                )
            : string()
                .matches(
                  lastNameRegex,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.basic.last_name'
                  )} `
                )
                .max(
                  40,
                  GetMaxLengthValidationMsg(
                    t('form_fields.basic.last_name'),
                    40
                  )
                ),
        };
        break;
    }
  }

  return schemaObject;
};
