import { Task } from '@smart/adb-shared';
import { AdbDatePicker } from '@smart/components-adb/adb-required-label/AdbDatePicker';
import ErrorField from '@smart/components-adb/atoms/ErrorField/ErrorField';
import FileUpload from '@smart/components-adb/atoms/FileUpload';
import {
  Button,
  Checkbox,
  Select,
  Text,
  TextInput,
} from '@smart/react-components';
import { SelectItemProps } from '@smart/react-components/dist/components/select/select-item';
import { useAgentContext } from 'contexts/agent-context';
import { useLanguageContext } from 'contexts/language-context';
import { useNotificationContext } from 'contexts/notification-context';
import { format } from 'date-fns';
import { Form, Formik, FormikProps } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingScreen from './LoadingScreen';
import './PosIdentModal.scss';
import FormSchema from './UploadIdSchema';
import { IdType, InitialFormValues, UploadIdFormValues } from './config';
import {
  getBackFileCaptionByIdType,
  getBackFileNameByIdType,
  getFrontFileCaptionByIdType,
  getFrontFileNameByIdType,
} from './utils';

const BASE_CLASS = 'adb-id-actions-modal';

const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64string = Buffer.from(reader.result as string)
        .toString()
        .split(',')[1];

      resolve(base64string);
    };
    reader.onerror = reject;
  });

const IdForm = ({
  onSubmit,
  setFileDataFront,
  setFileDataBack,
}: {
  onSubmit: (formValues: UploadIdFormValues) => Promise<void>;
  setFileDataFront: Dispatch<SetStateAction<File | undefined>>;
  setFileDataBack: Dispatch<SetStateAction<File | undefined>>;
}) => {
  const [idType, setIdType] = useState<IdType>('german-id');
  const { t } = useTranslation();

  const IDTypeOptions: SelectItemProps[] = [
    {
      label: t('task.pos_ident.form.german_id'),
      value: 'german-id',
    },
    {
      label: t('task.pos_ident.form.german_id_passport'),
      value: 'passport',
    },
    {
      label: t('task.pos_ident.form.foreign_id_passport'),
      value: 'foreign-id',
    },
  ];

  const CountryOptions: SelectItemProps[] = [
    {
      label: t('task.pos_ident.form.germany'),
      value: 'de',
    },
    {
      label: t('task.pos_ident.form.france'),
      value: 'fr',
    },
    {
      label: t('task.pos_ident.form.italy'),
      value: 'it',
    },
    {
      label: t('task.pos_ident.form.spain'),
      value: 'es',
    },
  ];

  const validationSchema = FormSchema(idType);

  return (
    <div>
      <Formik
        initialValues={InitialFormValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props: FormikProps<UploadIdFormValues>) => {
          const {
            handleChange,
            handleSubmit,
            values,
            setFieldValue,
            isValid,
            errors,
          } = props;

          return (
            <Form onSubmit={handleSubmit}>
              <div className={`${BASE_CLASS}__grid`}>
                <div>
                  <Select
                    items={IDTypeOptions}
                    label={t('task.pos_ident.form.document_type')}
                    value={values.idType}
                    onSelectionChange={(e) => {
                      setFieldValue('idType', e);
                      setIdType(e as IdType);
                    }}
                  >
                    {(items) =>
                      items.map((item) => (
                        <Select.Item
                          key={item.value}
                          value={item.value}
                          label={item.label}
                        />
                      ))
                    }
                  </Select>
                  <div>
                    <TextInput
                      id="idNumber"
                      name="idNumber"
                      value={values.idNumber}
                      onChange={handleChange}
                      label={t('task.pos_ident.form.id_number')}
                    />
                    {errors.idNumber && (
                      <ErrorField errorMsg={errors.idNumber} />
                    )}
                  </div>
                  <div>
                    <TextInput
                      id="idIssuingAuthority"
                      name="idIssuingAuthority"
                      value={values.idIssuingAuthority}
                      onChange={handleChange}
                      label={t('task.pos_ident.form.id_issuing_authority')}
                    />
                    {errors.idIssuingAuthority && (
                      <ErrorField errorMsg={errors.idIssuingAuthority} />
                    )}
                  </div>
                </div>
                <div>
                  <div>
                    <Select
                      items={CountryOptions}
                      value={values.nationality}
                      onSelectionChange={(e) => {
                        setFieldValue('nationality', e);
                      }}
                      label={t('task.pos_ident.form.id_country')}
                    >
                      {(items) =>
                        items.map((item) => (
                          <Select.Item
                            key={item.value}
                            value={item.value}
                            label={item.label}
                          />
                        ))
                      }
                    </Select>
                    {errors.nationality && (
                      <ErrorField errorMsg={errors.nationality} />
                    )}
                  </div>
                  <div>
                    <AdbDatePicker
                      id="dateOfIssue"
                      name="dateOfIssue"
                      selected={
                        (values.dateOfIssue && new Date(values.dateOfIssue)) ||
                        null
                      }
                      onChange={(e: Date) => {
                        if (e)
                          setFieldValue('dateOfIssue', format(e, 'yyyy-MM-dd'));
                      }}
                      label={t('task.pos_ident.form.id_created_date')}
                    />
                  </div>
                  <div>
                    <AdbDatePicker
                      id="dateOfExpiry"
                      name="dateOfExpiry"
                      selected={
                        (values.dateOfExpiry &&
                          new Date(values.dateOfExpiry)) ||
                        null
                      }
                      onChange={(e: Date) => {
                        if (e)
                          setFieldValue(
                            'dateOfExpiry',
                            format(e, 'yyyy-MM-dd')
                          );
                      }}
                      label={t('task.pos_ident.form.id_valid_until_date')}
                    />
                  </div>
                </div>
              </div>
              <div className={`${BASE_CLASS}__grid`}>
                <div>
                  <Text variant="cap-100">
                    {t(getFrontFileCaptionByIdType(values.idType))}
                  </Text>
                  <FileUpload
                    allowedFileExtensions={['jpg', 'jpeg', 'png', 'pdf']}
                    label={t('general.labels.file')}
                    maxFileSizeInBytes={6291456}
                    onChange={setFileDataFront}
                  />
                </div>
                <div>
                  <Text variant="cap-100">
                    {t(getBackFileCaptionByIdType(values.idType))}
                  </Text>
                  <FileUpload
                    allowedFileExtensions={['jpg', 'jpeg', 'png', 'pdf']}
                    label={t('general.labels.file')}
                    maxFileSizeInBytes={6291456}
                    onChange={setFileDataBack}
                  />
                </div>
              </div>
              <div>
                <Checkbox
                  name="imageResolutionCheck"
                  checked={values.imageResolutionCheck}
                  onCheckedChange={(e) => {
                    setFieldValue('imageResolutionCheck', e);
                  }}
                  className={`${BASE_CLASS}__checkbox`}
                >
                  {t('task.pos_ident.form.image_resolution_check')}
                </Checkbox>
                <Checkbox
                  name="idValidityCheck"
                  checked={values.idValidityCheck}
                  onCheckedChange={(e) => {
                    setFieldValue('idValidityCheck', e);
                  }}
                  className={`${BASE_CLASS}__checkbox`}
                >
                  {t('task.pos_ident.form.id_validity_check')}
                </Checkbox>
                <Checkbox
                  name="residencyForeignPassportCheck"
                  checked={values.residencyForeignPassportCheck}
                  onCheckedChange={(e) => {
                    setFieldValue('residencyForeignPassportCheck', e);
                  }}
                  className={`${BASE_CLASS}__checkbox`}
                >
                  {t('task.pos_ident.form.residency_foreign_passport_check')}
                </Checkbox>
                <Checkbox
                  name="agentAssuranceCheck"
                  value="agentAssuranceCheck"
                  onCheckedChange={(e) => {
                    setFieldValue('agentAssuranceCheck', e);
                  }}
                  className={`${BASE_CLASS}__checkbox`}
                >
                  {t('task.pos_ident.form.agent_assurance_check')}
                </Checkbox>
              </div>
              <div className={`${BASE_CLASS}__actions`}>
                <Button
                  disabled={!isValid}
                  variant="primary"
                  type="submit"
                  className={`${BASE_CLASS}__submit`}
                >
                  {t('task.pos_ident.form.save')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const UploadIdForm = ({
  task,
  onComplete,
}: {
  task: Task;
  onComplete: () => void;
}) => {
  const { locale } = useLanguageContext();
  const { t } = useTranslation();
  const { agent } = useAgentContext();
  const [loading, setLoading] = useState(false);
  const { addError, addSuccess } = useNotificationContext();

  const [fileDataFront, setFileDataFront] = useState<File>();
  const [fileDataBack, setFileDataBack] = useState<File>();

  const onSubmit = async (formValues: UploadIdFormValues) => {
    if (!fileDataFront || !fileDataBack)
      throw new Error('Either front or back missing');

    const [encodedFront, encodedBack] = await Promise.all([
      toBase64(fileDataFront),
      toBase64(fileDataBack),
    ]);

    const body = {
      cartId: task.cartId,
      customerType: task.customer.type,
      smartExpertId: agent.id,
      smartExpertName: agent.fullName,
      nationality: formValues.nationality,
      dateOfIssue: formValues.dateOfIssue,
      idIssuingAuthority: formValues.idIssuingAuthority,
      idNumber: formValues.idNumber,
      idType: formValues.idType,
      dateOfExpiry: formValues.dateOfExpiry,
      userId: task.customer.userId,
      locale,
      files: [
        {
          content: encodedFront,
          contentType: fileDataFront.type,
          extension: fileDataFront.name.split('.').pop(),
          filename: getFrontFileNameByIdType(formValues.idType as IdType),
        },
        {
          content: encodedBack,
          contentType: fileDataBack.type,
          extension: fileDataBack.name.split('.').pop(),
          filename: getBackFileNameByIdType(formValues.idType as IdType),
        },
      ],
    };

    setLoading(true);

    await fetch(`${import.meta.env.API_GATEWAY_BASE_URL}/ald/identity-upload`, {
      body: JSON.stringify(body),
      method: 'post',
    })
      .then(() => {
        addSuccess({
          label: t('task.pos_ident.form.complete_heading'),
          message: t('task.pos_ident.form.complete_text'),
        });
        onComplete();
      })
      .catch(() => {
        addError({
          label: t('task.pos_ident.form.error_heading'),
          message: t('task.pos_ident.form.error_text'),
        });
      })
      .finally(() => setLoading(false));
  };

  return loading ? (
    <LoadingScreen />
  ) : (
    <IdForm
      onSubmit={onSubmit}
      setFileDataBack={setFileDataBack}
      setFileDataFront={setFileDataFront}
    />
  );
};

export default UploadIdForm;
