import { AssetType, UpdateCarInput } from '@smart/adb-shared';
import { Text } from '@smart/react-components';
import { useMarketContext } from 'contexts/market-context';
import { useNotificationContext } from 'contexts/notification-context';
import { CarsDocument } from 'graphql/queries/cars.generated';
import { AllTasksDocument } from 'graphql/queries/tasks.generated';
import { enhanceError } from 'graphql/reactive-error';
import { useCurrentOutlet } from 'hooks/outlet';
import { GetCarDocument } from 'pages/tasks/tasks/queries.generated';
import { useTranslation } from 'react-i18next';
import AdbDialog from '../../AdbDialog/AdbDialog';
import { useModal } from '../../Modal';
import { CarInfleeterFormValues } from './CarInfleeter.config';
import { useUpdateCarMutation } from './queries.generated';

const UpdateCarModal = ({
  values,
  vin,
  uuid,
  setUpdated,
}: {
  values: CarInfleeterFormValues;
  vin: string;
  uuid?: string;
  setUpdated: (value: any) => void;
}) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const { market } = useMarketContext();
  const outlet = useCurrentOutlet();
  const { addSuccess } = useNotificationContext();

  const [updateProduct, { loading }] = useUpdateCarMutation({
    onError: (error) => {
      enhanceError({
        error,
        label: 'Failed to activate car',
        displayMessage: error.message,
      });
      closeModal();
    },
    onCompleted: () => {
      addSuccess({
        label: t(
          'outlet.showroom.demo_cars.notification.infleeted_democar_title'
        ),
        message: '',
      });
      closeModal();
      setUpdated(true);
    },
    refetchQueries: [
      {
        query: CarsDocument,
        variables: {
          input: uuid
            ? {
                uuid,
                type: AssetType.SellableVehicle,
              }
            : {
                bpid: outlet?.bpId,
                type: AssetType.DemoVehicle,
              },
        },
      },
      {
        query: AllTasksDocument,
        variables: {
          input: {
            outletId: outlet?.mcsId,
          },
        },
      },
      {
        query: GetCarDocument,
        variables: {
          uuid,
          input: uuid
            ? {
                uuid,
                type: AssetType.SellableVehicle,
                vin,
              }
            : {
                bpid: outlet?.bpId,
                type: AssetType.DemoVehicle,
                vin,
              },
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const activateCar = () => {
    let productInput: UpdateCarInput = {
      vin,
      licensePlateNumber: values.licensePlateNumber,
      registrationDate: values.registrationDate,
    };

    if (market === 'gb' && values.registrationDate === '') {
      productInput.preRegistrationDate = values.preRegistrationDate;
      const { registrationDate: removedRegistrationDate, ...rest } =
        productInput;
      productInput = rest;
    }

    if (market === 'gb' && values.preRegistrationDate !== '') {
      productInput.preRegistrationDate = values.preRegistrationDate;
    }

    updateProduct({ variables: { input: productInput } });
  };

  return (
    <AdbDialog
      id="purchased-product-dialog"
      buttons={{
        primary: {
          label: t('customer.purchased_products.buttons.confirm'),
          onClick: () => activateCar(),
          isLoading: loading,
        },
        secondary: {
          label: t('customer.purchased_products.buttons.cancel'),
          onClick: closeModal,
        },
      }}
    >
      <AdbDialog.Header>
        <Text variant="hl-100">
          {t('customer.purchased_products.dialog.confirm_warning')}
        </Text>
      </AdbDialog.Header>
      <AdbDialog.Content>
        <Text variant="cap-100">
          {t('customer.purchased_products.dialog.confirm_proceed_question')}
        </Text>
      </AdbDialog.Content>
    </AdbDialog>
  );
};

export { UpdateCarModal };
