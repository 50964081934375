import { useTranslation } from 'react-i18next';
import { string } from 'yup';
import { GetMaxLengthValidationMsg } from '../helpers/utils';

export const IdNumberSchema = (validationMarket: string, required = false) => {
  const { t } = useTranslation();

  const idNumberRegex = /^[a-zA-Z0-9\s]+$/g;

  let schemaObject = {};

  if (validationMarket) {
    switch (validationMarket) {
      case 'DE':
        schemaObject = {
          idNumber: required
            ? string()
                .required(
                  `${t('form_fields.basic.id_number')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`
                )
                .matches(
                  idNumberRegex,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.basic.id_number'
                  )} `
                )
                .max(
                  255,
                  GetMaxLengthValidationMsg(
                    t('form_fields.basic.id_number'),
                    255
                  )
                )
            : string()
                .matches(
                  idNumberRegex,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.basic.id_number'
                  )} `
                )
                .max(
                  255,
                  GetMaxLengthValidationMsg(
                    t('form_fields.basic.id_number'),
                    255
                  )
                ),
        };
        break;
      default:
        schemaObject = {
          idNumber: required
            ? string()
                .required(
                  `${t('form_fields.basic.id_number')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`
                )
                .matches(
                  idNumberRegex,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.basic.id_number'
                  )} `
                )
                .max(
                  255,
                  GetMaxLengthValidationMsg(
                    t('form_fields.basic.id_number'),
                    255
                  )
                )
            : string()
                .matches(
                  idNumberRegex,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.basic.id_number'
                  )} `
                )
                .max(
                  255,
                  GetMaxLengthValidationMsg(
                    t('form_fields.basic.id_number'),
                    255
                  )
                ),
        };
        break;
    }
  }

  return schemaObject;
};
