import { AmountOfCarsSchema } from '@ui/validations/schemas/AmountOfCars';
import { FleetSizeSchema } from '@ui/validations/schemas/FleetSize';
import { FrameworkAgreementSchema } from '@ui/validations/schemas/FrameworkAgreement';
import { FrameworkAgreementNumberSchema } from '@ui/validations/schemas/FrameworkAgreementNumber';
import { object } from 'yup';

const BusinessAdditionalInfoSchema = (validationMarket: any) => {
  let schemaObject = {};
  const daimlerFrameworkAgreement = FrameworkAgreementSchema(validationMarket);
  const frameworkAgreementNumber =
    FrameworkAgreementNumberSchema(validationMarket);
  const fleetSize = FleetSizeSchema(validationMarket);
  const amountOfCars = AmountOfCarsSchema(validationMarket);

  schemaObject = object().shape({
    ...daimlerFrameworkAgreement,
    ...frameworkAgreementNumber,
    ...fleetSize,
    ...amountOfCars,
  });

  return schemaObject;
};

export default BusinessAdditionalInfoSchema;
