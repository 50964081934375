import {
  Appointment,
  AppointmentStatus,
  AppointmentType,
  CarFilter,
  ExtendedCar,
  OrderPaymentType,
} from '@smart/adb-shared';
import ErrorField from '@smart/components-adb/atoms/ErrorField/ErrorField';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import { TypeAhead } from '@smart/components-adb/molecules/TypeAhead/TypeAhead';
import { TypeAheadPropList } from '@smart/components-adb/molecules/TypeAhead/TypeAhead.config';
import { Icon, Text, TextArea, TextInput } from '@smart/react-components';
import { formatDate, formatDateAndTime } from '@ui/library/helpers/date';
import { useFeatureFlag } from '@utils/configs/featureFlag';
import { getLanguage } from '@utils/market/getter';
import { useAgentContext } from 'contexts/agent-context';
import { useLanguageContext } from 'contexts/language-context';
import { useMarketContext } from 'contexts/market-context';
import { Formik } from 'formik';
import { useCarsQuery } from 'graphql/queries/cars.generated';
import { enhanceError } from 'graphql/reactive-error';
import { useCurrentOutlet } from 'hooks/outlet';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type HandoverContractTypeProps } from '../CreateDocumentType/CreateDocumentType.config';
import '../CreateDocumentType/CreateDocumentType.scss';
import { useCustomerOrderQuery } from '../CreateDocumentType/queries.generated';

import ChecklistHeader from './Checklist/ChecklistHeader';

import ChecklistItem from './Checklist/ChecklistItem';
import {
  ContractType,
  createHandoverSubmitHandler,
  getChecklistConfig,
  getHandoverContractTranslations,
  getInitialValues,
  translationsAsString,
} from './HandoverContract.config';
import HandoverContractSchema from './HandoverContractSchema';

const BASE_CLASS = 'adb-create-document';

export const OrderNumberInfoText = ({ text }: { text?: string }) => {
  const { t } = useTranslation();
  return (
    <div className={`${BASE_CLASS}__deactivated-warning`}>
      <Icon
        className={`${BASE_CLASS}__deactivated-warning-icon`}
        icon="warning"
      />
      <Text
        className={`${BASE_CLASS}__deactivated-warning-text`}
        variant="cap-300"
      >
        {text ?? t('feature_calendar.info_messages.visible_order_numbers')}
      </Text>
    </div>
  );
};

const getContractType = (
  paymentType: string | undefined | null
): ContractType | undefined => {
  if (!paymentType) return undefined;

  switch (paymentType) {
    case OrderPaymentType.Leasing:
    case OrderPaymentType.Finance:
    case OrderPaymentType.ThirdPartyLeasing:
    case OrderPaymentType.ThirdPartyFinancing:
      return ContractType.Leasing;

    case OrderPaymentType.Loa:
      return ContractType.Loa;

    default:
      return ContractType.CashBuy;
  }
};

const AppointmentSummary = ({
  appointment,
}: {
  appointment: Appointment | undefined;
}) => {
  const { t } = useTranslation();
  const { locale } = useLanguageContext();
  return (
    <div className={`${BASE_CLASS}__form-input`}>
      <TextInput
        id="appointment_date"
        value={
          appointment?.start
            ? formatDateAndTime(new Date(appointment?.start), locale)
            : ''
        }
        disabled
        label={t('customer.documents.upload.appointment')}
        type="text"
      />
      {!appointment && (
        <ErrorField
          errorMsg={t('form_fields.documents.errors.no_appointment')}
        />
      )}
      {appointment?.status === AppointmentStatus.Completed && (
        <ErrorField
          errorMsg={t('form_fields.documents.errors.already_completed')}
        />
      )}
    </div>
  );
};

const HandoverContract = ({
  formFooter,
  appointments,
  customer,
  createDocument,
  initialOrderNumber,
}: HandoverContractTypeProps) => {
  const { t } = useTranslation();
  const { market } = useMarketContext();
  const { t: defaultT } = useTranslation(undefined, {
    lng: getLanguage(market).locale,
  });

  const { agent } = useAgentContext();
  const { locale, language } = useLanguageContext();
  const outlet = useCurrentOutlet();

  const tireSpecFlag = useFeatureFlag({
    key: 'use-tirespecs',
    defaultValue: false,
  });

  const [orderNumber, setOrderNumber] = useState<string>(
    initialOrderNumber ?? ''
  );

  const { data, loading: purchasedProductsLoading } = useCarsQuery({
    variables: {
      input: {
        uuid: customer?.uuid ?? '',
      },
      filter: CarFilter.NonBooked,
    },
    skip: !customer?.uuid,
    onError: (error) => {
      console.error(error);
      enhanceError({
        error,
        label: 'Failed to fetch customer products',
        displayMessage: error.message,
      });
    },
  });
  const customerCars = data?.getCars.products;

  const handoverAppointments = appointments.filter(
    (item) => item.type === AppointmentType.Handover
  );

  const purchasedCarOptions: TypeAheadPropList[] = (customerCars ?? [])
    .map((car: ExtendedCar) => ({
      id: car.orderNumber ?? '',
      title: car.orderNumber ?? '',
    }))
    .sort((a, b) => Number(a.id) - Number(b.id));

  const { data: selectedOrder, loading: selectedOrderLoading } =
    useCustomerOrderQuery({
      variables: {
        input: {
          userId: agent?.email ?? '',
          customerId: customer?.userId ?? '',
          orderId: orderNumber,
        },
      },
      skip: !orderNumber || !customer?.userId,
      onError: (error) => {
        console.error(error);
        enhanceError({
          error,
          label: 'Failed to fetch selected order',
          displayMessage: error.message,
        });
      },
    });

  const selectedCar = customerCars?.find(
    (car: ExtendedCar) => car.orderNumber === orderNumber
  );

  const selectedAppointment = handoverAppointments.find(
    (a) => a.car?.carId === selectedCar?.carId
  );

  const canCreateHandoverContract = Boolean(
    selectedAppointment &&
      selectedAppointment.status !== AppointmentStatus.Completed &&
      selectedCar &&
      selectedCar.vin &&
      selectedCar.licensePlateNumber &&
      selectedCar.registrationDate &&
      outlet &&
      agent
  );

  const validationSchema = HandoverContractSchema();

  const type = getContractType(selectedOrder?.customerOrder.paymentType);

  const checklist = getChecklistConfig({
    market,
    type,
    order: selectedOrder?.customerOrder,
  });
  const initialValues = getInitialValues(checklist);
  const translations = getHandoverContractTranslations(
    defaultT,
    market,
    language,
    checklist,
    selectedOrder?.customerOrder
  );

  const handleHandoverSubmit = createHandoverSubmitHandler({
    createDocument,
    outlet,
    agent,
    selectedAppointment,
    selectedOrder: selectedOrder?.customerOrder,
    selectedCar,
    language,
    customer,
    tireSpecFlag,
    market,
    translations: translationsAsString({ translations }),
  });

  const onOrderNumberChange = (val: string) => {
    const purchasedCar = customerCars?.find((p) => p.orderNumber === val);

    if (purchasedCar) {
      setOrderNumber(val);
    } else {
      setOrderNumber('');
    }
  };

  const orderSelect = data?.getCars.products && (
    <div className={`${BASE_CLASS}__form-input`}>
      <TypeAhead
        customClass={
          orderNumber ? '' : 'adb-create-document__order-number-non-selected'
        }
        list={purchasedCarOptions}
        searchKey={orderNumber}
        name="orderNumber"
        delay={0}
        disableMinCharsToSearch
        onChange={onOrderNumberChange}
        onSelect={(e) => {
          onOrderNumberChange(`${e.id}`);
        }}
        label={t('form_fields.documents.vehicle_order_number')}
        disabled={
          purchasedCarOptions?.length === 0 ||
          typeof initialOrderNumber === 'string' ||
          selectedOrderLoading
        }
        schema={validationSchema}
      />
      {purchasedCarOptions?.length === 0 && <OrderNumberInfoText />}
    </div>
  );

  if (purchasedProductsLoading || selectedOrderLoading) {
    return (
      <>
        {orderSelect}
        <LoadingIndicator onFullPage />
      </>
    );
  }

  return (
    <>
      {orderSelect}
      {selectedOrder && selectedCar && (
        <Formik
          initialValues={{
            ...initialValues,
          }}
          validationSchema={validationSchema}
          onSubmit={handleHandoverSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
          }) => (
            <form autoComplete="on" onSubmit={handleSubmit}>
              <div className={`${BASE_CLASS}__form-input`}>
                <TextInput
                  id="license_plate"
                  value={selectedCar?.licensePlateNumber ?? ''}
                  disabled
                  label={t('car.assets.license_plate')}
                  type="text"
                />
                {!selectedCar?.licensePlateNumber && (
                  <ErrorField
                    errorMsg={t(
                      'form_fields.documents.errors.no_license_plate'
                    )}
                  />
                )}
              </div>
              <div className={`${BASE_CLASS}__form-input`}>
                <TextInput
                  id="registration_date"
                  value={
                    selectedCar?.registrationDate
                      ? formatDate(
                          new Date(selectedCar?.registrationDate),
                          locale
                        )
                      : ''
                  }
                  disabled
                  label={t('car.assets.registration_date')}
                  type="text"
                />
                {!selectedCar?.registrationDate && (
                  <ErrorField
                    errorMsg={t(
                      'form_fields.documents.errors.no_registration_date'
                    )}
                  />
                )}
                <AppointmentSummary appointment={selectedAppointment} />
              </div>

              {checklist?.checklists.customer && (
                <>
                  <ChecklistHeader id="customer" />
                  {checklist?.checklists.customer?.map((checkitem) => (
                    <ChecklistItem
                      key={`checklist_customer.${checkitem.key}`}
                      {...{
                        id: `checklist_customer.${checkitem.key}`,
                        error: errors.checklist_customer?.[checkitem.key] ?? '',
                        onCheckedChange: (checked: boolean) =>
                          setFieldValue(
                            `checklist_customer.${checkitem.key}`,
                            checked,
                            true
                          ),
                        touched: !!touched.checklist_customer?.[checkitem.key],
                        value: Boolean(
                          values.checklist_customer?.[checkitem.key]
                        ),
                        label: translations[checkitem.key],
                        checkboxType: checkitem.type,
                      }}
                    />
                  ))}
                </>
              )}
              {checklist?.checklists.agent && (
                <>
                  <ChecklistHeader id="agent" />
                  {checklist?.checklists.agent?.map((checkitem) => (
                    <ChecklistItem
                      key={`checklist_agent.${checkitem.key}`}
                      {...{
                        id: `checklist_agent.${checkitem.key}`,
                        error: errors.checklist_agent?.[checkitem.key] ?? '',
                        onCheckedChange: (checked: boolean) =>
                          setFieldValue(
                            `checklist_agent.${checkitem.key}`,
                            checked,
                            true
                          ),
                        touched: !!touched.checklist_agent?.[checkitem.key],
                        value: Boolean(values.checklist_agent?.[checkitem.key]),
                        label: translations[checkitem.key],
                        checkboxType: checkitem.type,
                      }}
                    />
                  ))}
                </>
              )}

              <div className={`${BASE_CLASS}__form-input`}>
                <TextArea
                  id="comment"
                  label={t('form_fields.documents.comment')}
                  caption={t('form_fields.document_handover_contract.comment')}
                  name="comment"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.comment}
                />
                <div className={`${BASE_CLASS}__timeout-warning`}>
                  <Icon
                    className={`${BASE_CLASS}__timeout-warning-icon`}
                    icon="warning"
                  />
                  <Text
                    className={`${BASE_CLASS}__timeout-warning-text`}
                    variant="cap-300"
                  >
                    {t(
                      'form_fields.document_handover_contract.handover_contract_5_days'
                    )}
                  </Text>
                </div>
                {errors?.comment && touched?.comment && (
                  <ErrorField errorMsg={errors?.comment} />
                )}
              </div>
              {formFooter(!canCreateHandoverContract)}
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

export default HandoverContract;
