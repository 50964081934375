import { PositionSchema } from '@ui/validations/schemas/Position';
import { object } from 'yup';

const PrimaryContactInfoSchema = (validationMarket: any) => {
  let schemaObject = {};
  const position = PositionSchema(validationMarket);

  schemaObject = object().shape({
    ...position,
  });

  return schemaObject;
};

export default PrimaryContactInfoSchema;
