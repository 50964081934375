import { MarketCode } from '@utils/market/types';
import { useMarketContext } from 'contexts/market-context';

/**  Email for help / support to channel requests from experts */
const SUPPORT_EMAIL_MAP: Partial<Record<MarketCode | 'fallback', string>> = {
  de: 'hallo@support.smart.com',
  se: 'hello@support.smart.com',
  es: 'hola@support.smart.com',
  pt: 'ola@support.smart.com',
  at: 'servus@support.smart.com',
  it: 'ciao@support.smart.com',
  fallback: 'eu.itoperations@smart.com',
};

export const GetSupportEmail = () => {
  const { market } = useMarketContext();

  return SUPPORT_EMAIL_MAP[market] ?? SUPPORT_EMAIL_MAP.fallback;
};

/** The SMART_COM_LINKS from env variables */
const {
  SMART_COM_PATH_CUSTOMIZER,
  SMART_COM_PATH_STORE,
  SMART_COM_PATH_GARAGE,
  SMART_COM_PATH_CART,
  SMART_COM_PATH_STOCK,
  SMART_COM_PATH_PRODUCT_DETAILS,
  SMART_COM_PATH_FLEET_ORDER,
  SMART_COM_PATH_STOCK_SEARCH,
} = import.meta.env;

export const SMART_COM_LINKS = {
  customizer: SMART_COM_PATH_CUSTOMIZER,
  smartStore: SMART_COM_PATH_STORE,
  garage: SMART_COM_PATH_GARAGE,
  shoppingCart: SMART_COM_PATH_CART,
  stocks: SMART_COM_PATH_STOCK,
  productDetails: SMART_COM_PATH_PRODUCT_DETAILS,
  fleetOrder: SMART_COM_PATH_FLEET_ORDER,
  stockSearch: SMART_COM_PATH_STOCK_SEARCH,
};
