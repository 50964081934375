import { CommunicationInfo, Maybe } from '@smart/adb-shared';
import ErrorField from '@smart/components-adb/atoms/ErrorField/ErrorField';
import Section from '@smart/components-adb/atoms/Section/Section';
import { Select, Text } from '@smart/react-components';
import useSelect from '@utils/hooks/useSelect';
import { useMarketContext } from 'contexts/market-context';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  CustomerForm,
  CustomerFormTypes,
  PrivateCommunicationInfoFormValues,
} from '../helper';
import { usePreferredOptionsQuery } from '../queries.generated';
import CommunicationChannelCheckbox from './CommunicationChannelCheckbox';
import CommunicationSchema from './PrivateCommunicationSchema';

const BASE_CLASS = 'adb-details';

type CommunicationInfoProps = CustomerForm & {
  communicationDetails: Maybe<CommunicationInfo> | undefined;
};

const PrivateCommunicationInfo = ({
  communicationDetails,
  handleCustomerDetailSubmit,
  formFooter,
  hasExpanded,
}: CommunicationInfoProps) => {
  const { t } = useTranslation();
  const { getTranslatedFilters } = useSelect();
  const { market } = useMarketContext();

  const { data } = usePreferredOptionsQuery();

  const preferredTimeOptions = data?.preferredOptions.preferredTime ?? [];

  // set default value for dropdown. If nothing is available from market, take the first id type value
  let defaultPrefTime = '';
  if (preferredTimeOptions.length) {
    defaultPrefTime = preferredTimeOptions[0].value;
  }

  const initialCommunicationInfo: PrivateCommunicationInfoFormValues = {
    preferredTime: communicationDetails?.preferredTime ?? defaultPrefTime,
    communicationPreferences: {
      Phone: communicationDetails?.communicationPreferences?.Phone ?? false,
      Email: communicationDetails?.communicationPreferences?.Email ?? false,
      SMS: communicationDetails?.communicationPreferences?.SMS ?? false,
      Direct_Mail:
        communicationDetails?.communicationPreferences?.Direct_Mail ?? false,
      Other: communicationDetails?.communicationPreferences?.Other ?? false,
    },
  };

  const onSubmit = (values: PrivateCommunicationInfoFormValues) => {
    handleCustomerDetailSubmit(values, CustomerFormTypes.CONTACT_INFO_PRIVATE);
  };

  const validationSchema = CommunicationSchema(market.toUpperCase());

  return (
    <Formik
      initialValues={initialCommunicationInfo}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values,
        touched,
        errors,
        dirty,
        setFieldValue,
        handleSubmit,
        handleReset,
      }: FormikProps<PrivateCommunicationInfoFormValues>) => (
        <Form autoComplete="on" onSubmit={handleSubmit} onReset={handleReset}>
          <Section
            expanded={!!hasExpanded}
            title={t('customer.details.communication_information')}
            hasAccordion
          >
            <div className={`${BASE_CLASS}__form-wrap`}>
              <div className={`${BASE_CLASS}__form-input`}>
                <Select
                  id="preferred_time"
                  name="preferredTime"
                  label={t('form_fields.communication.preferred_time')}
                  value={values.preferredTime}
                  onSelectionChange={(value) =>
                    setFieldValue('preferredTime', value, true)
                  }
                  items={getTranslatedFilters(
                    preferredTimeOptions ?? [],
                    'preferred_time'
                  )}
                >
                  {(items) =>
                    items.map((item) => (
                      <Select.Item key={item.value} {...item} />
                    ))
                  }
                </Select>
                {errors.preferredTime && touched.preferredTime && (
                  <ErrorField errorMsg={errors.preferredTime} />
                )}
              </div>
            </div>
            <div
              className={`${BASE_CLASS}__form-wrap ${BASE_CLASS}__form-wrap--preference-row`}
            >
              <Text variant="p-100" as="p">
                {t('form_fields.communication.preferred_communication')}
              </Text>

              <CommunicationChannelCheckbox
                value={values.communicationPreferences}
                onCheckedChange={(checked: boolean, id: string) =>
                  setFieldValue(id, checked, true)
                }
              />
            </div>
            {formFooter(CustomerFormTypes.CONTACT_INFO_PRIVATE, !dirty)}
          </Section>
        </Form>
      )}
    </Formik>
  );
};

export default PrivateCommunicationInfo;
