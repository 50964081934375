import { LicensePlateSchema } from '@ui/validations/schemas/LicensePlate';
import { PreRegistrationDate } from '@ui/validations/schemas/PreRegistrationDate';
import { RegistrationDateSchema } from '@ui/validations/schemas/RegistrationDate';
import { MarketCode } from '@utils/market/types';
import { object } from 'yup';

type InitialValues = {
  licensePlateNumber: string;
  registrationDate: string;
  preRegistrationDate: string;
};

const CarInfleeterSchema = ({
  initialValues,
  market,
}: {
  initialValues: InitialValues;
  market: MarketCode;
}) => {
  const isGB = market === 'gb';

  const registrationDateRequired = isGB
    ? Boolean(
        initialValues.preRegistrationDate && initialValues.licensePlateNumber
      )
    : true;

  const registrationDate = RegistrationDateSchema(
    market,
    registrationDateRequired
  );
  const preRegistrationDate = PreRegistrationDate(
    initialValues.preRegistrationDate,
    isGB
  );

  const licensePlateNumber = LicensePlateSchema(market);

  const schemaObject = object().shape({
    ...registrationDate,
    ...preRegistrationDate,
    ...licensePlateNumber,
  });

  return schemaObject;
};

export default CarInfleeterSchema;
