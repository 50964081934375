import { useTranslation } from 'react-i18next';
import { string } from 'yup';
import { GetMaxLengthValidationMsg, MarketCode } from '../helpers/utils';

export const StreetSchema = (
  validationMarket: MarketCode,
  required = false
) => {
  const { t } = useTranslation();

  let schemaObject = {};

  if (validationMarket) {
    switch (validationMarket) {
      case 'de':
        schemaObject = {
          street: required
            ? string().required(
                `${t('form_fields.basic.street_and_number')} ${t(
                  'general.error_messages.validations.is_required'
                )}`
              )
            : string().max(
                255,
                GetMaxLengthValidationMsg(
                  t('form_fields.basic.street_and_number'),
                  255
                )
              ),
        };
        break;
      default:
        schemaObject = {
          street: required
            ? string().required(
                `${t('form_fields.basic.street_and_number')} ${t(
                  'general.error_messages.validations.is_required'
                )}`
              )
            : string().max(
                255,
                GetMaxLengthValidationMsg(
                  t('form_fields.basic.street_and_number'),
                  255
                )
              ),
        };
        break;
    }
  }

  return schemaObject;
};
