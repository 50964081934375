import { useTranslation } from 'react-i18next';
import { string } from 'yup';

export const AppointmentTimeSchema = (
  validationMarket: string,
  required = false,
  isHandoverAppointment = false
) => {
  const { t } = useTranslation();

  let schemaObject = {};

  if (validationMarket) {
    switch (validationMarket) {
      case 'DE':
        schemaObject = {
          appointmentTime: required
            ? string().required(
                isHandoverAppointment
                  ? `${t('form_fields.documents.handover_appointment')} ${t(
                      'general.error_messages.validations.is_required'
                    )}`
                  : `${t('form_fields.documents.test_drive_appointment')} ${t(
                      'general.error_messages.validations.is_required'
                    )}`
              )
            : string(),
        };
        break;
      default:
        schemaObject = {
          appointmentTime: required
            ? string().required(
                isHandoverAppointment
                  ? `${t('form_fields.documents.handover_appointment')} ${t(
                      'general.error_messages.validations.is_required'
                    )}`
                  : `${t('form_fields.documents.test_drive_appointment')} ${t(
                      'general.error_messages.validations.is_required'
                    )}`
              )
            : string(),
        };
        break;
    }
  }

  return schemaObject;
};
