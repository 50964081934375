import { CarLine, CarModel } from '@ui/data-models/car/car.model';
import { FacetValue } from '@ui/data-models/facet/facet.model';

export const OrderCarModelFilterValues: FacetValue[] = [
  {
    code: 'productName',
    displayName: 'smart #1 Pro',
    initialQueryValue: CarModel.SMART_1_PRO,
    queryValue: CarModel.SMART_1_PRO,
    lineName: 'smart_1_pro',
    name: 'order_smart_1_pro',
    selected: false,
    label: 'vehicle_model',
  },
  {
    code: 'productName',
    displayName: 'smart #1 Pro+',
    initialQueryValue: CarModel.SMART_1_PROPLUS,
    queryValue: CarModel.SMART_1_PROPLUS,
    lineName: 'smart_1_proplus',
    name: 'order_smart_1_proplus',
    selected: false,
    label: 'vehicle_model',
  },
  {
    code: 'productName',
    displayName: 'smart #1 Premium',
    initialQueryValue: CarModel.SMART_1_PREMIUM,
    queryValue: CarModel.SMART_1_PREMIUM,
    lineName: 'smart_1_premium',
    name: 'order_smart_1_premium',
    selected: false,
    label: 'vehicle_model',
  },
  {
    code: 'productName',
    displayName: 'smart #1 Pulse',
    initialQueryValue: CarModel.SMART_1_PULSE,
    queryValue: CarModel.SMART_1_PULSE,
    lineName: 'smart_1_pulse',
    name: 'order_smart_1_pulse',
    selected: false,
    label: 'vehicle_model',
  },
  {
    code: 'productName',
    displayName: 'smart #1 BRABUS',
    initialQueryValue: CarModel.SMART_1_BRABUS,
    queryValue: CarModel.SMART_1_BRABUS,
    lineName: 'smart_1_brabus',
    name: 'order_smart_1_brabus',
    selected: false,
    label: 'vehicle_model',
  },
  {
    code: 'productName',
    displayName: 'smart #3 Pro',
    initialQueryValue: CarModel.SMART_3_PRO,
    queryValue: CarModel.SMART_3_PRO,
    lineName: 'smart_3_pro',
    name: 'order_smart_3_pro',
    selected: false,
    label: 'vehicle_model',
  },
  {
    code: 'productName',
    displayName: 'smart #3 Pro+',
    initialQueryValue: CarModel.SMART_3_PROPLUS,
    queryValue: CarModel.SMART_3_PROPLUS,
    lineName: 'smart_3_proplus',
    name: 'order_smart_3_proplus',
    selected: false,
    label: 'vehicle_model',
  },
  {
    code: 'productName',
    displayName: 'smart #3 Premium',
    initialQueryValue: CarModel.SMART_3_PREMIUM,
    queryValue: CarModel.SMART_3_PREMIUM,
    lineName: 'smart_3_premium',
    name: 'order_smart_3_premium',
    selected: false,
    label: 'vehicle_model',
  },
  {
    code: 'productName',
    displayName: 'smart #3 Pulse',
    initialQueryValue: CarModel.SMART_3_PULSE,
    queryValue: CarModel.SMART_3_PULSE,
    lineName: 'smart_3_pulse',
    name: 'order_smart_3_pulse',
    selected: false,
    label: 'vehicle_model',
  },
  {
    code: 'productName',
    displayName: 'smart #3 BRABUS',
    initialQueryValue: CarModel.SMART_3_BRABUS,
    queryValue: CarModel.SMART_3_BRABUS,
    lineName: 'smart_3_brabus',
    name: 'order_smart_3_brabus',
    selected: false,
    label: 'vehicle_model',
  },
];

export const OrderCarLineFilterValues: FacetValue[] = [
  {
    code: 'lineCode',
    displayName: 'Pro',
    initialQueryValue: CarLine.PRO,
    queryValue: CarLine.PRO,
    lineName: 'pro',
    name: 'order_line_pro',
    selected: false,
    label: 'vehicle_line',
  },
  {
    code: 'lineCode',
    displayName: 'Pro+',
    initialQueryValue: CarLine.PROPLUS,
    queryValue: CarLine.PROPLUS,
    lineName: 'proplus',
    name: 'order_line_proplus',
    selected: false,
    label: 'vehicle_line',
  },
  {
    code: 'lineCode',
    displayName: 'Premium',
    initialQueryValue: CarLine.PREMIUM,
    queryValue: CarLine.PREMIUM,
    lineName: 'premium',
    name: 'order_line_premium',
    selected: false,
    label: 'vehicle_line',
  },
  {
    code: 'lineCode',
    displayName: 'Pulse',
    initialQueryValue: CarLine.PULSE,
    queryValue: CarLine.PULSE,
    lineName: 'pulse',
    name: 'order_line_pulse',
    selected: false,
    label: 'vehicle_line',
  },
  {
    code: 'lineCode',
    displayName: 'Brabus',
    initialQueryValue: CarLine.BRABUS,
    queryValue: CarLine.BRABUS,
    lineName: 'brabus',
    name: 'order_line_brabus',
    selected: false,
    label: 'vehicle_line',
  },
];
