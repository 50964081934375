import { OrderItem, OrderStatus, ProductType } from '@smart/adb-shared';
import { Flex, Text } from '@smart/react-components';
import { DateFormats, getLocaleDate } from '@ui/library/helpers/date-locale';
import { useLanguageContext } from 'contexts/language-context';
import { useTranslation } from 'react-i18next';
import OrderItemFeature from './OrderItemFeature';
import { OrderItemSection } from './OrderItems.config';
import './OrderItems.scss';

const BASE_CLASS = 'adb-order-items';

interface OrderItemFormatted {
  key: string;
  value: string;
  extraText?: string;
}

const OrderDetail = ({ orderDetail }: OrderItemSection) => {
  const { t } = useTranslation();
  const { locale } = useLanguageContext();

  const getCancellationStatus = (item: OrderItem) => {
    if (
      !(
        item.productType === ProductType.Accessory &&
        (item.productStatus === OrderStatus.Cancelled ||
          item.productStatus === OrderStatus.Cancelling)
      )
    ) {
      return '';
    }

    return item.productStatus === OrderStatus.Cancelled
      ? ` (${t('orders.order_status.cancelled')})`
      : ` (${t('orders.order_status.cancelling')})`;
  };

  const orderItemWithVehicleType = orderDetail?.items?.find(
    (order) => order.productType === ProductType.Vehicle
  );

  const orderCarDetails =
    orderItemWithVehicleType &&
    orderItemWithVehicleType?.features &&
    orderItemWithVehicleType.features?.length > 0
      ? orderItemWithVehicleType.features.filter(
          (item) => item?.categoryType !== undefined
        )
      : [];

  const orderItemsWithoutVehicleType =
    orderDetail?.items &&
    orderDetail?.items?.length > 0 &&
    orderDetail?.items.filter(
      (item) => item.productType !== ProductType.Vehicle
    );

  const orderItemsFormatted: OrderItemFormatted[] =
    orderItemsWithoutVehicleType && orderItemsWithoutVehicleType.length > 0
      ? orderItemsWithoutVehicleType.map((item) => {
          if (
            item.productType === ProductType.Warranty &&
            item.features?.some((x) => x.code === 'EXTENDED_WARRANTY')
          ) {
            return {
              key: t('orders.order_details.warranty'),
              value: item.productName ?? '',
              extraText: t('orders.order_details.extended_warranty'),
            };
          }
          return {
            key: t(`orders.order_details.${item.productType?.toLowerCase()}`),
            value: `${item.productName ?? ''}${getCancellationStatus(item)}`,
          };
        })
      : [];

  const orderProductTypes: OrderItemFormatted[] =
    orderItemsFormatted.length > 0
      ? Object.values(
          orderItemsFormatted.reduce(
            (
              previous: Record<string, OrderItemFormatted>,
              current: OrderItemFormatted
            ) => {
              if (!previous[current.key]) previous[current.key] = current;
              else {
                previous[current.key].value += `, ${current.value}`;
              }

              return previous;
            },
            {}
          )
        ).filter((item) => typeof item !== 'string')
      : [];

  return (
    <Flex direction="column" width="full" gap={300}>
      <Text variant="lbl-200">{t('orders.order_details.title')}</Text>
      <Flex
        alignContent="space-between"
        width="full"
        wrap="wrap"
        gap={300}
        className={`${BASE_CLASS}__features`}
      >
        <OrderItemFeature
          title={t('orders.order_details.order_id')}
          text={orderDetail?.orderId}
        />
        <OrderItemFeature
          title={t('orders.order_details.order_date')}
          text={
            (orderDetail?.createdDate &&
              getLocaleDate(
                orderDetail.createdDate,
                locale,
                DateFormats.DATE
              )) ??
            '-'
          }
        />
        <OrderItemFeature
          title={t('orders.order_details.order_source')}
          text={orderDetail?.source ?? ''}
        />
        {orderCarDetails.map((item) => (
          <OrderItemFeature
            key={item.code}
            title={
              item.categoryType
                ? t(`car.features.${item.categoryType.toLowerCase()}`)
                : t('car.assets.line')
            }
            text={item.name ?? '-'}
          />
        ))}
        {orderProductTypes.map((item) => (
          <OrderItemFeature
            key={item.key}
            title={item.key}
            text={item.value}
            extraText={item.extraText}
          />
        ))}
        <OrderItemFeature
          title={t('orders.order_details.tire_specification')}
          text={orderDetail?.wheelSpecification ?? ''}
        />
      </Flex>
    </Flex>
  );
};

export default OrderDetail;
