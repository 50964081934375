import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import AdbLayout from 'layouts/spacing-layout/AdbLayout';
import { Suspense, lazy } from 'react';
import './Documents.scss';
import DocumentsSearch from './Search/Search';
import { useDocumentsContext } from './documents-context';

const DocumentsList = lazy(() => import('./DocumentsList/DocumentsList'));

const BASE_CLASS = 'adb-customer-document';

const DocumentsOld = () => {
  const { documentLoading } = useDocumentsContext();

  return (
    <div className={BASE_CLASS}>
      <AdbLayout.Header backgroundColor="bg-level-2">
        <DocumentsSearch />
      </AdbLayout.Header>
      <AdbLayout.Content>
        <Suspense fallback={<LoadingIndicator />}>
          <DocumentsList />
        </Suspense>
        {documentLoading && (
          <div className={`${BASE_CLASS}__fullscreen-loading`}>
            <LoadingIndicator />
          </div>
        )}
      </AdbLayout.Content>
    </div>
  );
};
export default DocumentsOld;
