import { useTranslation } from 'react-i18next';
import { string } from 'yup';
import { GetMaxLengthValidationMsg } from '../helpers/utils';

export const PlaceOfBirthSchema = (
  validationMarket: string,
  required = false
) => {
  const { t } = useTranslation();

  const placeOfBirthRegex = /^[A-Za-z.]+$/g;

  let schemaObject = {};

  if (validationMarket) {
    switch (validationMarket) {
      case 'DE':
        schemaObject = {
          placeOfBirth: required
            ? string()
                .required(
                  `${t('form_fields.basic.place_of_birth')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`
                )
                .matches(
                  placeOfBirthRegex,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.basic.place_of_birth'
                  )} `
                )
                .max(
                  100,
                  GetMaxLengthValidationMsg(
                    t('form_fields.basic.place_of_birth'),
                    100
                  )
                )
            : string()
                .matches(
                  placeOfBirthRegex,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.basic.place_of_birth'
                  )} `
                )
                .max(
                  100,
                  GetMaxLengthValidationMsg(
                    t('form_fields.basic.place_of_birth'),
                    100
                  )
                ),
        };
        break;
      default:
        schemaObject = {
          placeOfBirth: required
            ? string()
                .required(
                  `${t('form_fields.basic.place_of_birth')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`
                )
                .matches(
                  placeOfBirthRegex,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.basic.place_of_birth'
                  )} `
                )
                .max(
                  100,
                  GetMaxLengthValidationMsg(
                    t('form_fields.basic.place_of_birth'),
                    100
                  )
                )
            : string()
                .matches(
                  placeOfBirthRegex,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.basic.place_of_birth'
                  )} `
                )
                .max(
                  100,
                  GetMaxLengthValidationMsg(
                    t('form_fields.basic.place_of_birth'),
                    100
                  )
                ),
        };
        break;
    }
  }

  return schemaObject;
};
