import {
  Maybe,
  PrivateAdditionalInfo as PrivateAdditionalInfoI,
} from '@smart/adb-shared';
import { AdbDatePicker } from '@smart/components-adb/adb-required-label/AdbDatePicker';
import ErrorField from '@smart/components-adb/atoms/ErrorField/ErrorField';
import Section from '@smart/components-adb/atoms/Section/Section';
import { Select, TextInput } from '@smart/react-components';
import useSelect from '@utils/hooks/useSelect';
import { useMarketContext } from 'contexts/market-context';
import { format } from 'date-fns';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  CustomerForm,
  CustomerFormTypes,
  PrivateAdditionalInfoFormValues,
} from '../helper';
import { usePreferredOptionsQuery } from '../queries.generated';
import PrivateAdditionalInfoSchema from './PrivateAdditionalInfoSchema';

const BASE_CLASS = 'adb-details';

type PrivateAdditionalInfoProps = CustomerForm & {
  privateAdditionalDetails: Maybe<PrivateAdditionalInfoI> | undefined;
};

const PrivateAdditionalInfo = ({
  privateAdditionalDetails,
  handleCustomerDetailSubmit,
  formFooter,
  hasExpanded,
}: PrivateAdditionalInfoProps) => {
  const { t } = useTranslation();
  const { getTranslatedFilters } = useSelect();
  const { market } = useMarketContext();

  const { data } = usePreferredOptionsQuery();

  const countryOptions = data?.preferredOptions.countryCode ?? [];
  const idTypeOptions = data?.preferredOptions.idType ?? [];
  const nationalityOptions = data?.preferredOptions.nationality ?? [];

  const filterCurrentCountry =
    countryOptions &&
    countryOptions.filter((setting) => setting.value.toLowerCase() === market);

  // set default value for dropdown. If nothing is available from market, take the first country value
  let defaultCountry = '';
  if (filterCurrentCountry && filterCurrentCountry.length > 0) {
    defaultCountry = filterCurrentCountry[0].value;
  } else if (countryOptions.length) {
    defaultCountry = countryOptions[0].value;
  }

  // set default value for dropdown. If nothing is available, take the first id type value
  let defaultIdType = '';
  if (idTypeOptions.length) {
    defaultIdType = idTypeOptions[0].value;
  }

  // set default value for dropdown. If nothing is available, take the first nationality value
  let defaultNationality = '';
  if (nationalityOptions.length) {
    defaultNationality = nationalityOptions[0].value;
  }

  const initialPrivateAdditionalInfo: PrivateAdditionalInfoFormValues = {
    dateOfBirth: privateAdditionalDetails?.dateOfBirth ?? '',
    placeOfBirth: privateAdditionalDetails?.placeOfBirth ?? '',
    nationality: privateAdditionalDetails?.nationality ?? defaultNationality,
    idNumber: privateAdditionalDetails?.idNumber ?? '',
    idType: privateAdditionalDetails?.idType ?? defaultIdType,
    countryOfId:
      privateAdditionalDetails?.countryOfId &&
      privateAdditionalDetails?.countryOfId !== ''
        ? privateAdditionalDetails.countryOfId
        : defaultCountry,
  };

  const onSubmit = (values: PrivateAdditionalInfoFormValues) => {
    handleCustomerDetailSubmit(
      values,
      CustomerFormTypes.ADDITIONAL_INFO_PRIVATE
    );
  };

  const validationSchema = PrivateAdditionalInfoSchema(market.toUpperCase());

  return (
    <Formik
      initialValues={initialPrivateAdditionalInfo}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values,
        touched,
        errors,
        dirty,
        handleChange,
        handleSubmit,
        handleReset,
        setFieldValue,
      }: FormikProps<PrivateAdditionalInfoFormValues>) => (
        <Form autoComplete="on" onSubmit={handleSubmit} onReset={handleReset}>
          <Section
            expanded={!!hasExpanded}
            title={t('customer.details.additional_information')}
            hasAccordion
          >
            <div className={`${BASE_CLASS}__form-wrap`}>
              <div className={`${BASE_CLASS}__form-input`}>
                <AdbDatePicker
                  showIcon
                  id="customer_date_of_birth"
                  name="dateOfBirth"
                  selected={values.dateOfBirth}
                  label={t('form_fields.basic.date_of_birth')}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date: Date) =>
                    date &&
                    setFieldValue(
                      'dateOfBirth',
                      format(new Date(date), 'yyyy-MM-dd')
                    )
                  }
                  schema={validationSchema}
                />
                {(values.dateOfBirth || touched.dateOfBirth) &&
                  errors.dateOfBirth && (
                    <ErrorField errorMsg={errors.dateOfBirth} />
                  )}
              </div>
              <div className={`${BASE_CLASS}__form-input`}>
                <TextInput
                  id="customer_place_of_birth"
                  type="text"
                  name="placeOfBirth"
                  label={t('form_fields.basic.place_of_birth')}
                  value={values.placeOfBirth}
                  onChange={handleChange}
                />
                {errors.placeOfBirth && touched.placeOfBirth && (
                  <ErrorField errorMsg={errors.placeOfBirth} />
                )}
              </div>
              <div className={`${BASE_CLASS}__form-input`}>
                <Select
                  id="nationality"
                  name="nationality"
                  label={t('form_fields.basic.nationality')}
                  value={values.nationality}
                  onSelectionChange={(value) =>
                    setFieldValue('nationality', value, true)
                  }
                  items={getTranslatedFilters(
                    nationalityOptions ?? [],
                    'nationality'
                  )}
                >
                  {(items) =>
                    items.map((item) => (
                      <Select.Item key={item.value} {...item} />
                    ))
                  }
                </Select>
                {errors.nationality && touched.nationality && (
                  <ErrorField errorMsg={errors.nationality} />
                )}
              </div>
            </div>
            <div className={`${BASE_CLASS}__form-wrap`}>
              <div className={`${BASE_CLASS}__form-input`}>
                <TextInput
                  id="customer_id_number"
                  type="text"
                  name="idNumber"
                  label={t('form_fields.basic.id_number')}
                  value={values.idNumber}
                  onChange={handleChange}
                />
                {errors.idNumber && touched.idNumber && (
                  <ErrorField errorMsg={errors.idNumber} />
                )}
              </div>
              <div className={`${BASE_CLASS}__form-input`}>
                <Select
                  id="id_type"
                  name="idType"
                  label={t('form_fields.basic.id_type')}
                  value={values.idType}
                  onSelectionChange={(value) =>
                    setFieldValue('idType', value, true)
                  }
                  items={getTranslatedFilters(idTypeOptions ?? [], 'id_type')}
                >
                  {(items) =>
                    items.map((item) => (
                      <Select.Item key={item.value} {...item} />
                    ))
                  }
                </Select>
                {errors.idType && touched.idType && (
                  <ErrorField errorMsg={errors.idType} />
                )}
              </div>
              <div className={`${BASE_CLASS}__form-input`}>
                <Select
                  id="country_of_ID"
                  name="countryOfId"
                  label={t('form_fields.basic.country_id')}
                  value={values.countryOfId}
                  onSelectionChange={(value) =>
                    setFieldValue('countryOfId', value, true)
                  }
                  items={getTranslatedFilters(
                    countryOptions ?? [],
                    'country_code'
                  )}
                >
                  {(items) =>
                    items.map((item) => (
                      <Select.Item key={item.value} {...item} />
                    ))
                  }
                </Select>
                {errors.countryOfId && touched.countryOfId && (
                  <ErrorField errorMsg={errors.countryOfId} />
                )}
              </div>
            </div>
            {formFooter(CustomerFormTypes.ADDITIONAL_INFO_PRIVATE, !dirty)}
          </Section>
        </Form>
      )}
    </Formik>
  );
};

export default PrivateAdditionalInfo;
