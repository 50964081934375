import { useTranslation } from 'react-i18next';
import { string } from 'yup';

export const PreferredTimeSchema = (
  validationMarket: string,
  required = false
) => {
  const { t } = useTranslation();

  let schemaObject = {};

  if (validationMarket) {
    switch (validationMarket) {
      case 'EN':
      case 'DE':
        schemaObject = {
          preferredTime: string(),
        };
        break;
      default:
        schemaObject = {
          preferredTime: required
            ? string().required(
                `${t('form_fields.communication.preferred_time')} ${t(
                  'general.error_messages.validations.is_required'
                )}`
              )
            : string(),
        };
        break;
    }
  }

  return schemaObject;
};
